import "./settings.styl"
import template from "./settings.pug"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Flag } from "@/flags"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { CompanyStore } from "@/stores/company-store.core"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

### VM ###
import { MyProfileViewModel } from "@/views/settings/vm/my-profile/my-profile"
import { CompanyViewModel as LegacyCompanyViewModel } from "@/views/settings/vm/company/company"
import { CompanyViewModel } from "@/views/settings/vm/company/company.core"
import { GroupsViewModelCore } from "@/views/settings/vm/groups/groups.core"
import { PositionsViewModel } from "@/views/settings/vm/positions/positions"
import { TagsViewModel } from "@/views/settings/vm/tags/tags.core"
import { AssignmentStatusesViewModel } from "@/views/settings/vm/assignment-statuses/assignment-statuses"
import { AssignmentAlertsViewModel } from "@/views/settings/vm/assignment-alerts/assignment-alerts"
import { OauthAppsViewModel } from "@/views/settings/vm/oauth-apps/oauth-apps.core"
import { CustomFieldsViewModel } from "@/views/settings/vm/custom-fields/custom-fields"
import { QRCodesViewModel } from "@/views/settings/vm/qr-codes/qr-codes"
import { CostingViewModel } from "@/views/settings/vm/costing/costing"
import { PermissionsViewModel } from "@/views/settings/vm/permissions/permissions"
import { ViewPreferencesViewModel } from "@/views/settings/vm/view-preferences/view-preferences"
import { IntegratedFieldsViewModel } from "@/views/settings/vm/integrated-fields/integrated-fields"
import { IntegrationSettingsViewModel } from "@/views/settings/vm/integration-settings/integration-settings"
import { NotificationsViewModel } from "@/views/settings/vm/notifications/notifications.core"
import { ResetDataViewModel } from "@/views/settings/vm/reset-data/reset-data"
import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

### Framework Includes ###
import ko from "knockout"

export class SettingsViewModel extends PageContentViewModel
   constructor: (queryParams, companyId) ->
      assertArgs(arguments, nullable(Object), String)
      super(template())

      ###------------------------------------
         Permissions
      ------------------------------------###
      @isAdmin = authManager.isAdmin
      @canViewCompanySettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_COMPANY_SETTINGS)
      @canViewPermissionSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PERMISSION_SETTINGS)
      @canViewGroupSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_GROUP_SETTINGS)
      @canViewPositionSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_POSITION_SETTINGS)
      @canViewTagsSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_TAGS_SETTINGS)
      @canViewStatusSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_STATUS_SETTINGS)
      @canViewAlertsSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_ALERTS_SETTINGS)
      @canViewCostingSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_COSTING_SETTINGS)
      @canViewCustomFieldsSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_CUSTOM_FIELDS_SETTINGS)
      @canViewQrCodesSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_QR_CODES_SETTINGS)
      @canViewNotificationsSettings = authManager.checkAuthAction(PermissionLevel.Action.VIEW_NOTIFICATIONS_SETTINGS)

      # For demo data reset. Only shown to demo account admins with a @procore.com email.
      ENABLE_RESET_DEMO_DATA = LaunchDarklyBrowser.getFlagValue("enable-reset-demo-data")
      isDemo = window.config.LC_TIER == "prod" and window.config.LC_RIG == "demo"
      userEmail = authManager.authedUser().email()
      isProcoreEmail = userEmail.endsWith('@procore.com') or userEmail.endsWith('@laborchart.com')
      @canResetData = ENABLE_RESET_DEMO_DATA and isDemo and @isAdmin and isProcoreEmail


      ###------------------------------------
         Data Properties
      ------------------------------------###
      @customFieldModuleEnabled = authManager.companyModules()?.customFields or false
      @qrCodesModuleEnabled = authManager.companyModules()?.qrCodes or false

      @companyId = companyId
      @company = ko.observable()
      @searchQuery = ko.observable()
      @selectedPanel = ko.observable()
      @loadCompany().then(() =>
         if queryParams?.tab?
            @selectPanel(queryParams.tab)
         else
            @selectPanel("my-profile")
      )

   selectPanel: (panelName) ->
      assertArgs(arguments, String)
      matched = false
      if panelName == "my-profile"
         matched = true
         @setChild('settings-pane', new MyProfileViewModel())
      else if panelName == "view-preferences"
         matched = true
         @setChild('settings-pane', new ViewPreferencesViewModel())
      else if panelName == "company" and @canViewCompanySettings
         matched = true
         if Flag.ENABLE_COMPANY_VIEW_MODEL_CORE
            @setChild('settings-pane', new CompanyViewModel(@companyId))
         else
            @setChild('settings-pane', new LegacyCompanyViewModel(@companyId))
      else if panelName == "groups" and @canViewGroupSettings
         matched = true
         @setChild('settings-pane', new GroupsViewModelCore())
      else if panelName == "notifications" and @canViewNotificationsSettings
         matched = true
         @setChild('settings-pane', new NotificationsViewModel())
      else if panelName == "job-titles" and @canViewPositionSettings
         matched = true
         @setChild('settings-pane', new PositionsViewModel())
      else if panelName == "tags" and @canViewTagsSettings
         matched = true
         @setChild('settings-pane', new TagsViewModel(@company().tag_categories))
      else if panelName == "statuses" and @canViewStatusSettings
         matched = true
         @setChild('settings-pane', new AssignmentStatusesViewModel())
      else if panelName == "assignment-alerts" and @canViewAlertsSettings
         matched = true
         @setChild('settings-pane', new AssignmentAlertsViewModel())
      else if panelName == "oauth-apps" and @canViewCompanySettings
         matched = true
         @setChild('settings-pane', new OauthAppsViewModel())
      else if panelName == "custom-fields" and @canViewCustomFieldsSettings
         return unless @customFieldModuleEnabled
         matched = true
         @setChild('settings-pane', new CustomFieldsViewModel())
      else if panelName == "integrated-fields" and @canViewCustomFieldsSettings
         return unless @isAdmin()
         matched = true
         @setChild('settings-pane', new IntegratedFieldsViewModel())
      else if panelName == "integration-settings" and @isAdmin
         matched = true
         @setChild('settings-pane', new IntegrationSettingsViewModel())
      else if panelName == "qr-codes" and @canViewQrCodesSettings
         return unless @qrCodesModuleEnabled
         matched = true
         @setChild('settings-pane', new QRCodesViewModel())
      else if panelName == "costing" and @canViewCostingSettings
         matched = true
         @setChild('settings-pane', new CostingViewModel())
      else if panelName == "permissions" and @canViewPermissionSettings
         matched = true
         @setChild('settings-pane', new PermissionsViewModel())
      else if panelName == "reset-data" and @canResetData
         matched = true
         @setChild('settings-pane', new ResetDataViewModel())

      if matched
         router.updateUrlQueryParam(null, 'tab', panelName)
         @selectedPanel(panelName)
      else
         @setChild('settings-pane', new MyProfileViewModel())

   setSearchQuery: (query) =>
      assertArgs(arguments, Function)
      @searchQuery(query())

   loadCompany: ->
      try
         company = (await CompanyStore.getCompany().payload).data
         @company(company)
      catch err
         return console.log("error: ", err)

SettingsViewModel.RecievableError = {
   USER_NOT_FOUND: 'userNotFound'
   WRONG_PASSWORD: 'wrongPassword'
}