// Markup & styles
import template from "./requests-list-3.pug";
import "./requests-list-3.styl";

// Base & Utils
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel";
import { router } from "@/lib/router";
import * as BrowserStorageUtils from "@/lib/utils/browser-storage";
import { DateUtils } from "@/lib/utils/date";
import type {
   MaybeComputed,
   Observable,
   ObservableArray,
   PureComputed,
   Subscription,
} from "knockout";
import { unwrap } from "knockout";
import { observable, observableArray, pureComputed } from "knockout";
import { App } from "../app";
import { ValidationUtils } from "@/lib/utils/validation";
import { Format } from "@/lib/utils/format";
import { accumulateCustomFieldChipFilters } from "@/lib/utils/custom-field-helper";
import { formatName } from "@/lib/utils/preferences";

// Filters
import type {
   ChipFilterParams,
   Filter,
   LabeledFilterOptions,
} from "@/lib/components/chip-filter/chip-filter";
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator";
import {
   buildDateFilterInstance,
   serializedFilters,
   serializeLegacyFilters,
} from "@/lib/utils/chip-filter-helper";

// Auth & Stores
import { authManager } from "@/lib/managers/auth-manager";
import { DefaultStore, defaultStore } from "@/stores/default-store";
import type { GetGroupEntitiesData } from "@/stores/group-store";
import { groupStore } from "@/stores/group-store";
import { PeopleStore } from "@/stores/people-store";
import type { GetFilteredRequestParams } from "@/stores/request-store";
import { requestStore } from "@/stores/request-store";
import type {
   AssignmentSupportData,
   NestedComputedAssignmentSupportData,
} from "@/stores/assignment-2-store.core";
import { TagStore } from "@/stores/tag-store.core";
import { SavedViewStore } from "@/stores/saved-view-store.core";
import { CustomFieldStore } from "@/stores/custom-field-store.core";
import { StatusStore } from "@/stores/status-store.core";
import { LegacyStore } from "@/stores/legacy-store.core";

// Modules, API & Schemas
import type {
   FindRequestsPaginatedQueryParams,
   SerializedFindRequest,
} from "@laborchart-modules/lc-core-api/dist/api/requests/find-requests";
import type { FindRequestsSortBy } from "@laborchart-modules/common/dist/reql-builder/procedures/find-requests";
import { ColumnEntityType } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/column-header";
import { Order } from "@laborchart-modules/common/dist/reql-builder/query-definitions";
import type { CreateRequestPayload } from "@laborchart-modules/lc-core-api/dist/api/requests/create-request";
import type { SerializedTag } from "@laborchart-modules/common/dist/rethink/serializers/tag-serializer";
import { RequestsColumnKey } from "@laborchart-modules/common/dist/rethink/schemas/column-headers/requests-columns";
import type { SerializedFindAssignment } from "@laborchart-modules/lc-core-api";
import type { SerializedPerson } from "@laborchart-modules/common/dist/rethink/serializers";
import { CustomFieldEntity } from "@laborchart-modules/common/dist/rethink/schemas/enums/custom-fields";
import type { SerializedCustomField } from "@laborchart-modules/common/dist/rethink/serializers/custom-field-serializer";
import { noStatusFilter } from "@laborchart-modules/common/dist/reql-builder/procedures/common/find-records";

// Models
import { PermissionLevel } from "@/models/permission-level";
import type { ColumnHeaderData } from "@/models/column-header";
import { ColumnHeader } from "@/models/column-header";
import { ValueSet } from "@/models/value-set";

// Grid & Editors
import { ColorCircleTextCell } from "@/lib/components/grid/cells/color-circle-text-cell";
import { LinkedTextCell } from "@/lib/components/grid/cells/linked-text-cell";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import { CheckboxColumnGroupManager } from "@/lib/components/grid/column-groups/checkbox-column-group-manager";
import type { ColumnTemplate } from "@/lib/components/grid/grid-column-manager";
import { GridColumnManager } from "@/lib/components/grid/grid-column-manager";
import type {
   GridSortOrder,
   GridCellFocus,
   VirtualGridParams,
} from "@/lib/components/grid/virtual-grid/virtual-grid";
import { FocusMode } from "@/lib/components/grid/virtual-grid/virtual-grid";
import {
   canManageOthersValidator,
   NO_PERMISSON_TO_EDIT_REQUEST_MESSAGE,
   RequestList3GridStore,
} from "./requests-list-3-grid-store";
import { TagsCell } from "@/lib/components/grid/cells/tags-cell";
import { EditActionCell } from "@/lib/components/grid/cells/edit-action-cell";
import { CommentActionCell } from "@/lib/components/grid/cells/comment-action-cell";
import { FillRequestCell } from "@/lib/components/grid/cells/fill-request-cell";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import { createColumnGroupForEach } from "@/lib/components/grid/grid-column-group";
import { GridCursorState } from "@/lib/components/grid/grid-column";
import { DisabledEditor } from "@/lib/components/editors/disabled-editor/disabled-editor";
import { ColumnWidthDefault, ColumnWidthMin } from "@/lib/components/grid/column-defaults";
import { LoadingState } from "@/lib/components/grid/grid-store";

// Modals
import { modalManager as legacyModalManager } from "@/lib/managers/modal-manager";
import { modalManager } from "@/lib/managers/modal-manager-2/modal-manager-2";
import { Modal } from "@/lib/components/modals/modal";
import {
   RequestDetailsModal,
   RequestDetailsModalPane,
} from "@/lib/components/modals/request-details-modal/request-details-modal";
import {
   AssignmentDetailsModal,
   AssignmentDetailsModalType,
} from "@/lib/components/modals/assignment-details-modal/assignment-details-modal";

// Managers
import {
   notificationManagerInstance,
   Icons,
   Notification,
} from "@/lib/managers/notification-manager";

// Reports
import type { FileType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { ReportType } from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";

// UI
import { TagExpirationState } from "@/lib/components/tags/tag-chip";
import type { ConfigureColumnsParams } from "@/lib/components/configure-columns/configure-columns";
import type { SearchBarParams } from "@/lib/components/search-bar/search-bar";
import type { ButtonIconParams } from "@/lib/components/button-icon/button-icon";
import { ListViewExportModalPane } from "@/lib/components/modals/list-view-export-modal-pane";
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane";
import { ProcessingNoticePaneViewModel } from "@/lib/components/modals/processing-notice-pane";

// Flags
import { Flag } from "@/flags";
import type { BatchDeleteValidator } from "@/lib/components/batch-actions/batch-delete/batch-delete";
import type { Conflict } from "@/lib/components/batch-actions/batch-actions";
import type { NotifiableError } from "@bugsnag/js";
import Bugsnag from "@bugsnag/js";
import { BUGSNAG_META_TAB, buildUserData } from "@/lib/utils/bugsnag-content-helper";

const DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS = DisabledEditor.create({
   text: "You do not have permission to manage others' requests.",
});

const ITEMS_PER_REQUEST = 40;

const DISABLED_EDITOR_CANNOT_EDIT_REQUEST = DisabledEditor.create({
   text: NO_PERMISSON_TO_EDIT_REQUEST_MESSAGE,
});

export class RequestsList3ViewModel extends PageContentViewModel {
   readonly allowExportingData = authManager.checkAuthAction(
      PermissionLevel.Action.ALLOW_EXPORTING_DATA,
   );

   readonly canManageOthersRequests = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_OTHERS_REQUESTS,
   );

   readonly canManageOwnRequests = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_REQUESTS,
   );

   readonly canManageAssignments = authManager.checkAuthAction(
      PermissionLevel.Action.MANAGE_ASSIGNMENTS,
   );

   readonly canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT);

   readonly canViewRequestsNotes = authManager.checkAuthAction(
      PermissionLevel.Action.VIEW_REQUESTS_NOTES,
   );

   readonly canManageRequests = authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS);

   readonly canViewAllStatuses = authManager.checkAuthAction(
      PermissionLevel.Action.CAN_VIEW_ALL_STATUSES,
   );

   readonly hasTagCategoriesEnabled = Boolean(authManager.companyModules()?.tagCategories);

   // TODO: Put a real type here.
   private savedView: any;

   readonly sortOrder = observable<GridSortOrder>({
      columnKey: "id",
      direction: Order.ASCENDING,
   });
   readonly store: Observable<RequestList3GridStore>;
   readonly selectedIds = observableArray<string>();
   readonly viewConfigured = observable(false);

   readonly chipFilterMediator = new ChipFilterMediator();
   readonly filterChips: ObservableArray<Filter>;
   readonly cellFocus = observable<GridCellFocus | null>(null);
   readonly labeledFilterOptions = pureComputed(() => this.createLabeledFilterOptions());
   readonly noRequestsMessage = pureComputed<string>(() => {
      return this.filterChips().length ? "No Matching Requests" : "No Requests";
   });
   readonly paddingAroundColumns = pureComputed<number>(() => {
      return this.canManageRequests ? 8 : 16;
   });

   // Batch editor customization.
   readonly batchEditors = pureComputed(() => {
      return this.store()
         .batchEditFields()
         .concat(this.columnManager()!.getCustomColumnBatchEditors());
   });

   //#region Batch Delete
   readonly batchDeleteValidator: BatchDeleteValidator<SerializedFindRequest> = (
      selectedRequests,
   ) => {
      const conflicts: Array<Conflict<SerializedFindRequest>> = [];

      if (this.canManageOwnRequests && this.canManageOthersRequests) {
         return {
            conflicts,
            valid: selectedRequests,
         };
      }

      const activeUserId = authManager.authedUser()?.id;
      const valid = selectedRequests.filter((request) => {
         if (!this.canManageOwnRequests && request.creator_id === activeUserId) {
            conflicts.push({
               record: request,
               reason: "You do not have permission to delete requests made by you",
            });
            return false;
         } else if (!this.canManageOthersRequests && request.creator_id !== activeUserId) {
            conflicts.push({
               record: request,
               reason: "You do not have permission to delete requests made by others",
            });
            return false;
         }

         return true;
      });

      return {
         conflicts,
         valid,
      };
   };

   readonly batchDeleteAction = async (
      stagedForDeletion: SerializedFindRequest[],
   ): Promise<void> => {
      const deletedIds = await this.store().batchDeleteRows(stagedForDeletion);
      this.selectedIds.removeAll(deletedIds);
   };
   //#endregion

   readonly selectedRequests = pureComputed<SerializedFindRequest[]>(() => {
      return this.selectedIds().map(
         (id) =>
            this.store()
               .rows()
               .find((request) => request.id == id)!,
      );
   });

   readonly requestCountText = pureComputed(() => {
      if (this.store().loadingState() == LoadingState.INITIAL) return "Showing ...";
      const selectedCount = this.selectedRequests().length;
      const totalPossible = this.store().totalPossible();
      if (this.columnManager()!.columnGroups().length === 0 || totalPossible === 0)
         return "Showing 0";
      if (selectedCount === totalPossible) {
         return `All ${totalPossible} Requests Selected`;
      }
      if (selectedCount > 0 && totalPossible > 0) {
         return `${selectedCount}/${totalPossible} Selected`;
      }
      return `Showing ${totalPossible}`;
   });

   readonly conflictModalColumnGroups = pureComputed(() => {
      return this.createRowIdentifierColumnGroups();
   });
   readonly columnManager = observable<GridColumnManager<SerializedFindRequest> | null>(null);

   readonly searchQuery = observable(null);

   private readonly groupEntities = observable<GetGroupEntitiesData | null>();
   private readonly allTags = observableArray<SerializedTag>();
   private readonly statusOptions = observableArray<ValueSet<string>>();

   private readonly assignmentCreationSupportData = observable<AssignmentSupportData | null>(null);
   private readonly assignmentSupportData: NestedComputedAssignmentSupportData = {
      companyTbdWeeks: pureComputed(
         () => this.assignmentCreationSupportData()?.companyTbdWeeks ?? 0,
      ),
      groupedCostCodeOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.groupedCostCodeOptions ?? {},
      ),
      groupedLabelOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.groupedLabelOptions ?? {},
      ),
      overtimeDayRates: pureComputed(
         () => this.assignmentCreationSupportData()?.overtimeDayRates ?? null,
      ),
      paidShiftHours: pureComputed(() => this.assignmentCreationSupportData()?.paidShiftHours ?? 8),
      projectOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.projectOptions ?? [],
      ),
      resourceOptions: pureComputed(
         () => this.assignmentCreationSupportData()?.resourceOptions ?? [],
      ),
      statusOptions: pureComputed(() => this.assignmentCreationSupportData()?.statusOptions ?? []),
   };

   private readonly subscriptions: Subscription[] = [];
   private isSelectedGroupIdChanging = false;

   private readonly checkboxColumnGroupManager: CheckboxColumnGroupManager<SerializedFindRequest>;

   readonly disableExportBtn = pureComputed(() => {
      const totalRows = this.store().rows().length;
      const totalColumns = this.columnManager()!.columnGroups().length;
      return totalColumns === 0 || totalRows === 0;
   });

   // Used in the pug template. These are here to assure types are correct.
   /* eslint-disable no-unused-vars */
   private readonly buttonIconParams: ButtonIconParams;
   private readonly chipFilterParams: ChipFilterParams;
   private readonly configureColumnsParams: ConfigureColumnsParams;
   private readonly searchBarParams: SearchBarParams;
   private readonly virtualGridParams: VirtualGridParams<SerializedFindRequest>;
   /* eslint-enable no-unused-vars */

   // TODO: Remove when ENABLE_BATCH_DELETE flag is no longer needed
   private readonly batchDeleteEnabled: boolean = Flag.ENABLE_BATCH_DELETE;

   constructor(queryParams: Record<string, string | boolean> | null = {}) {
      super(template(), "List");

      // Use the stored filter chips if they exist or reset to the defaults.
      this.filterChips = observableArray(this.getPageFilterChips());
      this.store = observable(this.createStore({ startFromCurrentCursor: true }));

      this.checkboxColumnGroupManager = new CheckboxColumnGroupManager({
         selectedIds: this.selectedIds,
         allIds: pureComputed<string[]>(() => {
            return this.store()
               .rows()
               .map((request) => request.id);
         }),
      });

      this.columnManager(
         new GridColumnManager({
            listViewType: PeopleStore.ListViewType.Requests,
            templates: this.createColumnTemplates(),
            customFieldConfig: {
               fieldEntity: ColumnEntityType.REQUESTS,
               isEditableProvider: (meta, row) =>
                  authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS) &&
                  (row == null ||
                     authManager.checkAuthAction(PermissionLevel.Action.MANAGE_OTHERS_REQUESTS) ||
                     row.creator_id == authManager.authedUserId()),
               disabledEditorProvider: (property, row) => {
                  if (
                     authManager.checkAuthAction(PermissionLevel.Action.MANAGE_REQUESTS) &&
                     !authManager.checkAuthAction(PermissionLevel.Action.MANAGE_OTHERS_REQUESTS) &&
                     row.creator_id != authManager.authedUserId()
                  ) {
                     return DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS;
                  }
                  return null;
               },
               // Currently all request custom fields are always visible.
               isVisibleProvider: () => true,
               saveProvider: (rows, columnHeader, value) => {
                  return this.store().updateCustomFields(rows, columnHeader.meta()!, value);
               },
               valueExtractor: (request, columnHeader) =>
                  this.customFieldValueExtractor(request, columnHeader),
               batchEditValidator: (request: SerializedFindRequest): string | null => {
                  const canManageOthers = canManageOthersValidator(request);
                  if (!canManageOthers.status) return canManageOthers.message;
                  return null;
               },
            },
            projectRolesConfig: null,
            // hasSortableCustomColumns: true, // TODO: Get this supported.
         }),
      );

      if (queryParams && queryParams.viewId) {
         this.columnManager()!.load(true);
         this.loadSavedView(queryParams.viewId as string);
      } else {
         const viewOptions: { [key: string]: any } = {};

         if (queryParams && (queryParams.sortBy || queryParams.sortDirection)) {
            const sortOrder = this.sortOrder();
            viewOptions["sortOrder"] = {
               columnKey: queryParams.sortBy || sortOrder.columnKey,
               direction: queryParams.sortDirection
                  ? queryParams.sortDirection == Order.DESCENDING
                     ? Order.DESCENDING
                     : Order.ASCENDING
                  : sortOrder.direction,
            };
         }
         if (queryParams && ValidationUtils.validateInput(queryParams.query)) {
            viewOptions["search"] = decodeURIComponent(queryParams.query);
         }

         viewOptions["filters"] = this.getPageFilterChips();

         this.setupViewConfig(viewOptions);
         this.columnManager()!.load();
      }

      this.assignmentCreationSupportData = observable(null);
      this.load();

      this.buttonIconParams = {
         onClick: this.onExportClicked,
         isDisabled: this.disableExportBtn,
         tooltip: "Export",
      };
      this.chipFilterParams = {
         labeledOptions: this.labeledFilterOptions,
         defaultChips: [],
         chips: this.filterChips,
         mediator: this.chipFilterMediator,
         mainFilterTitle: "Request Filters",
      };
      this.configureColumnsParams = {
         paneProvider: this.columnManager()!.createCustomColumnHeaderPane,
         onUpdate: this.columnManager()!.updateColumnHeaders,
         onReset: this.columnManager()!.resetColumnHeaders,
      };
      this.searchBarParams = {
         query: this.searchQuery,
         callback: this.onRequestsSearch,
         placeholder: "Search Requests",
         showInfoPopup: true,
         searchableFields: ["Project Name", "Project Number"],
      };
      this.virtualGridParams = {
         columnGroups: this.columnManager()!.columnGroups,
         store: this.store(),
         sortOrder: this.sortOrder as Observable<GridSortOrder | null>,
         selectedIds: this.selectedIds,
         cellFocus: this.cellFocus,
         emptyMessage: this.noRequestsMessage,
         hasRowBorders: true,
         paddingAroundColumns: this.paddingAroundColumns,
         paddingAroundProgressBars: 8,
         paddingAroundInitialProgressBar: 48,
         hasScrollBars: true,
         focusMode: FocusMode.CELL,
      };
   }

   // Wrapper function browser-storage's for getPageFilterChips.
   private getPageFilterChips(key?: string) {
      const filterChips = BrowserStorageUtils.getPageFilterChips(key) || [];
      let shouldUpdateFilters = false;
      // Can remove this after July 1st, 2022.
      const transformedFilters = filterChips.map((el) => {
         if (el.filterName === "Status" || el.filterName === "Flag") {
            shouldUpdateFilters = true;
            return {
               ...el,
               filterName: "Request Status",
               property: "status_id",
            };
         } else {
            return el;
         }
      });

      if (shouldUpdateFilters) {
         BrowserStorageUtils.storePageFilterChips(transformedFilters);
      }
      return transformedFilters;
   }

   load = async (): Promise<void> => {
      await Promise.all([
         this.loadGroupEntities(),
         this.loadAdmSupportData(),
         this.loadAllTags(),
         this.loadStatusData(),
      ]);
   };

   private async loadStatusData() {
      const stream = await StatusStore.findStatusesStream({}).stream;
      const statuses: Array<ValueSet<string>> = [];
      for await (const status of stream) {
         statuses.push(
            new ValueSet({
               id: status.id,
               name: status.name,
               value: status.id,
               color: status.color,
               selected: false,
            }),
         );
      }
      this.statusOptions(statuses);
   }

   private async loadAllTags() {
      const tagStream = await TagStore.findTagsStream({
         sort_direction: Order.ASCENDING,
      }).stream;

      const tags: SerializedTag[] = [];
      for await (const tag of tagStream) {
         tags.push(tag);
      }
      this.allTags(tags);
   }

   onExportClicked = (): void => {
      const { group_id, ...queryParams } = this.createQueryParams();
      const pane = new ListViewExportModalPane(
         {
            ...queryParams,
            group_id: group_id ? group_id : "my-groups",
            column_headers: this.columnManager()!
               .getActiveColumnHeaders()
               .map((header) => {
                  const value = header.allToJson();
                  delete value.baggage;
                  // Make sure null values are undefined.
                  if (value.key_id == null) {
                     delete value.key_id;
                  }
                  return value;
               }),
            display_last_names_first:
               authManager.authedUser()?.preferences()?.displayLastNamesFirst() || false,
         },
         "Export Requests Report",
         "requests-report",
         ReportType.REQUESTS_LIST,
      );
      const modal = new Modal();
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "list-view-export-modal-pane" });
   };

   onRequestsSearch = (): void => {
      this.reload({ startFromCurrentCursor: false });
   };

   onCreateNewRequest(): void {
      this.onCreateOrEditRequest({
         request: null,
      });
   }

   onCreateOrEditRequest({
      request,
      initialPane = RequestDetailsModalPane.EDIT_REQUEST,
   }: {
      request: PureComputed<SerializedFindRequest> | null;
      initialPane?: RequestDetailsModalPane;
   }): void {
      if (!this.canManageRequests) return;

      const createRequest = async (request: CreateRequestPayload) => {
         await this.store().createRequestFromModal(request);
         modalManager.clearModal();
         this.reload({ startFromCurrentCursor: false });
      };
      const deleteRequest = async (request: SerializedFindRequest) => {
         this.selectedIds(this.selectedIds().filter((reqId) => reqId !== request.id));
         await this.store().deleteRequestFromModal(request);
      };

      const showCreateOrEditModal = () => {
         modalManager.setModal({
            modal: RequestDetailsModal.factory({
               createRequest,
               customFields: pureComputed(() => this.columnManager()!.entityCustomFields()),
               deleteRequest,
               initialPane,
               request,
               requestSize: modalManager.requestSize,
               supportData: this.assignmentSupportData,
               tags: pureComputed(() => this.allTags()),
               updateRequest: this.store().updateRequestFromModal,
            }),
         });
      };

      if (this.assignmentCreationSupportData() != null) {
         return showCreateOrEditModal();
      }
      const pane = new ProcessingNoticePaneViewModel("Loading Support Data", true);
      const modal = new Modal();
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "processing-notice-modal" });

      const isCreateOrEditModalReady = pureComputed(() => {
         return (
            legacyModalManager.animatingModalIn() == false &&
            this.assignmentCreationSupportData() != null
         );
      });

      const isReadySubscription = isCreateOrEditModalReady.subscribe((isReady) => {
         if (isReady !== true) return;
         legacyModalManager.maybeCancelModal(() => {
            showCreateOrEditModal();
         });
         isReadySubscription.dispose();
      });
   }

   async onFillRequest({
      request,
      person,
   }: {
      request: PureComputed<SerializedFindRequest>;
      person: SerializedPerson;
   }): Promise<void> {
      if (!this.canManageRequests || !this.canManageAssignments) return;

      const customFieldStream = await CustomFieldStore.findCustomFieldsStream({
         is_on_entities: [CustomFieldEntity.ASSIGNMENT],
      }).stream;
      const assignmentCustomFields: SerializedCustomField[] = [];
      const assignmentCustomFieldIds: Set<string> = new Set();
      for await (const field of customFieldStream) {
         assignmentCustomFields.push(field);
         assignmentCustomFieldIds.add(field.id);
      }

      const newAssignment = pureComputed<SerializedFindAssignment>((): SerializedFindAssignment => {
         const req = request();
         const mappedCustomFields = req.custom_fields.filter((f) =>
            assignmentCustomFieldIds.has(f.field_id),
         );
         const validatedCategory =
            req.project.categories.findIndex((cat) => cat.id == req.category_id) != -1
               ? req.category
               : null;
         const validatedSubcategory =
            validatedCategory != null &&
            validatedCategory.subcategories.findIndex((sc) => sc.id == req.subcategory_id) != -1
               ? req.subcategory
               : null;
         return {
            category_id: validatedCategory?.id ?? null,
            category: validatedCategory,
            company_id: req.company_id,
            created_at: Date.now(),
            creator_id: authManager.authedUserId(),
            custom_fields: mappedCustomFields,
            end_day: req.end_day,
            end_time: req.end_time,
            id: "",
            overtime: false,
            percent_allocated: req.percent_allocated,
            person: person as any,
            project_id: req.project_id,
            project: req.project as any, // TODO: Remove these as any's
            resource_id: person.id,
            start_day: req.start_day,
            start_time: req.start_time,
            status_id: req.status_id,
            status: req.status,
            subcategory_id: validatedSubcategory?.id ?? null,
            subcategory: validatedSubcategory,
            work_days: req.work_days,
         };
      });

      modalManager.setModal({
         modal: AssignmentDetailsModal.factory({
            assignment: newAssignment,
            createAssignment: async (assignment, showAlertModal) => {
               const requestData = request();
               await this.store().fillRequestSave({
                  assignment,
                  requestData: {
                     id: requestData.id,
                     instuctionText: requestData.instruction_text,
                     scopeOfWork: requestData.work_scope_text,
                  },
                  showAlertModal,
               });
               this.reload({ startFromCurrentCursor: false });
            },
            customFields: pureComputed(() => assignmentCustomFields),
            deleteAssignment: async () => {},
            assignmentDetailsModalType: AssignmentDetailsModalType.FILL_REQUEST,
            requestSize: modalManager.requestSize,
            supportData: this.assignmentSupportData,
            updateAssignment: async () => {},
         }),
      });
   }

   dispose(next: (() => void) | null): void {
      this.subscriptions.forEach((s) => s.dispose());
      this.columnManager()!.dispose();
      this.checkboxColumnGroupManager.dispose();
      if (next) next();
   }

   private setupViewConfig = (options: {
      sortOrder?: { columnKey: string; direction: Order };
      filters?: Filter[];
      search?: string;
      columnHeaders?: ColumnHeader[];
   }) => {
      if (options.sortOrder) {
         this.sortOrder(options.sortOrder);
      }
      if (options.filters) {
         this.filterChips(options.filters);
         setTimeout(() => {
            this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));
         }, 0);
      }

      if (options.search) {
         this.searchQuery(options.search);
      }

      if (options.columnHeaders) {
         // This should only get hit by saved views.
         this.columnManager()?.updateColumnHeaders(options.columnHeaders, true);
      }

      this.reload({ startFromCurrentCursor: false });
      this.setupSubscriptions();
   };

   private setupSubscriptions = () => {
      this.subscriptions.push(
         authManager.selectedGroupId.subscribe(this.onSelectedGroupIdChanged, this),
         this.filterChips.subscribe(this.onFilterChipsChanged, this),
         this.sortOrder.subscribe(this.onSortOrderChanged, this),
         this.checkboxColumnGroupManager.hasAllChecked.subscribe(
            this.onHasAllRowsCheckedChanged,
            this,
         ),
         this.searchQuery.subscribe(this.onSearchQueryChanged, this),
      );
   };

   private async loadSavedView(savedViewId: string) {
      const savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload;
      // Will capture the non-archived custom fields. Will use to make sure we only load still-valid custom fields in our saved view
      const savedCustomFields: SerializedCustomField[] = [];
      const stream = await CustomFieldStore.findCustomFieldsStream({
         is_on_entities: [CustomFieldEntity.REQUEST],
      }).stream;
      for await (const customField of stream) {
         savedCustomFields.push(customField);
      }

      this.savedView = savedViewPayload.data;

      // TODO: Check that the view is valid.

      this.setTitle(this.savedView.name);

      const viewOptions: { [key: string]: any } = {};

      if (this.savedView.view_config.sort_by && this.savedView.view_config.sort_direction) {
         viewOptions["sortOrder"] = {
            columnKey: this.savedView.view_config.sort_by,
            direction: this.savedView.view_config.sort_direction,
         };
      }

      if (this.savedView.chip_filters) {
         viewOptions["filters"] = this.savedView.chip_filters.map((item: any) => {
            return Format.snakeCaseObjectToCamelCase(item);
         });
      }

      if (this.savedView.search) {
         viewOptions["search"] = this.savedView.search;
      }

      if (this.savedView.view_config.column_headers) {
         viewOptions["columnHeaders"] = this.savedView.view_config.column_headers
            .map((item: ColumnHeaderData) => {
               return new ColumnHeader(item);
            })
            // There was a bug where archived custom fields were still on a saved view, and then were throwing an error when that saved view tried to load.
            // To address this, we're adding this filter to filter-out any old/archived custom-fields from the saved view's column headers
            .filter(
               (header: ColumnHeader) =>
                  // If the column header does not have a meta value, then it is not a custom field, so we keep it
                  header.meta() == null ||
                  // If the column header is a custom field, then we need to make sure it's valid.
                  // I don't like how messy this comparison logic reads, but it works.
                  savedCustomFields.find(
                     (savedCustomField) =>
                        savedCustomField.integration_name === header.meta()?.field_property,
                  ),
            );
      }

      this.setupViewConfig(viewOptions);
   }

   private canEditRequest(request: MaybeComputed<SerializedFindRequest>): boolean {
      return this.canManageOthersRequests
         ? true
         : unwrap(request).creator_id === authManager.authedUser()?.id;
   }

   private onCommentRequest(request: PureComputed<SerializedFindRequest>) {
      this.onCreateOrEditRequest({
         initialPane: RequestDetailsModalPane.COMMENTS,
         request,
      });
   }

   private async loadAdmSupportData() {
      let result: any = null;
      const group_id =
         authManager.selectedGroupId() === "my-groups" ? undefined : authManager.selectedGroupId();

      const loadAttempt = async (id: string | undefined) => {
         result = await LegacyStore.getAssignmentCreationSupportData({ group_id: id }).payload;
         this.assignmentCreationSupportData(
            LegacyStore.formatAssignmentCreationSupportData(result.data),
         );
      };

      const logToBugsnag = (err: unknown, context: string) => {
         Bugsnag.notify(err as NotifiableError, (event) => {
            event.context = context;
            event.addMetadata(
               BUGSNAG_META_TAB.USER_DATA,
               buildUserData(authManager.authedUser()!, authManager.activePermission),
            );
            event.addMetadata("getAssignmentCreationSupportData", { result });
            event.addMetadata("group_id", { group_id });
         });
      };

      // First attempt: load support data for the specific group
      try {
         await loadAttempt(group_id);
      } catch (err) {
         // Second attempt: load support data for the specific group
         try {
            await new Promise((res) => setTimeout(res, 250));
            await loadAttempt(group_id);
            console.info("second attempt to load support data was successful");
         } catch (err) {
            logToBugsnag(err, "request-list__loadAdmSupportData__1__group-attempt");

            // Third attempt: If we can't load the specific group support data, load all support data
            try {
               await new Promise((res) => setTimeout(res, 250));
               await loadAttempt(undefined);
               console.info("third attempt to load support data was successful");
            } catch (err) {
               console.error("third attempt to load support data failed:", err);
               logToBugsnag(err, "request-list__loadAdmSupportData__2__global-attempt");

               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "An unexpected error occurred while loading data.",
                  }),
               );
            }
         }
      }
   }

   private onHasAllRowsCheckedChanged(hasAllRowsChecked: boolean) {
      if (hasAllRowsChecked) {
         this.store().loadAll();
      } else {
         this.store().cancelLoadAll();
      }
   }

   private getFormattedColumnHeaders() {
      return this.columnManager()!
         .getActiveColumnHeaders()
         .map((column) => ({
            key: column.key(),
            sequence: column.sequence(),
            sortable: column.sortable(),
            ...(column.name() ? { name: column.name()! } : {}),
         }));
   }

   private async downloadCsvExport(): Promise<void> {
      const { search, sortAscending, sortBy, filters, timezone } = this.createQueryParamsLegacy();
      return requestStore.getFilteredRequestsReportCsv({
         columnHeaders: this.getFormattedColumnHeaders(),
         search,
         sortAscending,
         sortBy,
         filters,
         timezone,
         dateFormat: defaultStore.getDateFormat(),
      });
   }

   private async downloadPdfExport(config: {
      fileType: FileType.PDF;
      paper_size: { width: number; height: number };
   }): Promise<void> {
      const { search, sortAscending, sortBy, filters, timezone } = this.createQueryParamsLegacy();
      return requestStore.getFilteredRequestsReportPdf({
         timeString: defaultStore.getCompanyFormattedReportHeader(),
         paper_size: config.paper_size,
         columnHeaders: this.getFormattedColumnHeaders(),
         search,
         sortAscending,
         sortBy,
         filters,
         timezone,
         dateFormat: defaultStore.getDateFormat(),
      });
   }

   private loadGroupEntities(): Promise<void> {
      const entities = ["users", "projects", "positions"];
      if (this.hasTagCategoriesEnabled) {
         entities.push("categorized-tags");
      } else {
         entities.push("tags");
      }

      if (authManager.companyModules()?.customFields) {
         entities.push("requests_custom_field_filters");
      }

      return new Promise<void>((resolve, reject) => {
         groupStore.getGroupEntities(authManager.selectedGroupId(), entities, (err, data) => {
            if (err) {
               Bugsnag.notify(err as NotifiableError, (event) => {
                  event.context = "request-list_loadGroupEntities";
                  event.addMetadata(
                     BUGSNAG_META_TAB.USER_DATA,
                     buildUserData(authManager.authedUser()!, authManager.activePermission),
                  );
                  event.addMetadata("groupEntities", this.groupEntities);
               });
               notificationManagerInstance.show(
                  new Notification({
                     icon: Icons.WARNING,
                     text: "An unexpected error prevented the filters from loading.",
                  }),
               );
               return reject(err);
            }
            this.groupEntities(data);
            resolve();
         });
      });
   }

   private onSelectedGroupIdChanged(groupId: string) {
      // Set isSelectedGroupIdChanging to notify the rest of the listeners that
      // the group ID is changing and they should respond accordingly.
      this.isSelectedGroupIdChanging = true;

      // Update the filter chips from browser storage. Specify the path manually because the URL
      // has not yet been updated.
      this.filterChips(
         BrowserStorageUtils.getPageFilterChips(
            `${BrowserStorageUtils.BrowserLocalStorageKey.CHIP_FILTERS}_/groups/${groupId}/requests-list`,
         ) || [],
      );
      this.chipFilterMediator.updateVisibleFilters(this.filterChips().slice(0));

      // Create a new store to use the new group ID and filter chips.
      this.reload({ startFromCurrentCursor: true });
      this.loadGroupEntities();
      this.isSelectedGroupIdChanging = false;
   }

   private onFilterChipsChanged(filterChips: Filter[]) {
      if (!this.isSelectedGroupIdChanging) {
         this.reload({ startFromCurrentCursor: false });
         BrowserStorageUtils.storePageFilterChips(filterChips);
      }
   }

   private createLabeledFilterOptions(): LabeledFilterOptions {
      const groupEntities = this.groupEntities();
      if (!groupEntities) {
         return {};
      }
      const statusFilterOptions = Format.keyableSort(this.statusOptions(), "name");
      if (this.canViewAllStatuses) {
         statusFilterOptions.unshift(
            new ValueSet({
               name: noStatusFilter.name,
               value: noStatusFilter.value_sets[0].value,
               selected: false,
            }),
         );
      }

      const filterOptions: LabeledFilterOptions = {
         Starting: observable<any>(buildDateFilterInstance("start_day")),
         Ending: observable<any>(buildDateFilterInstance("end_day")),
         "Created By": observable<any>({
            property: "creator_id",
            values: Format.keyableSort(groupEntities.userOptions || [], "name"),
         }),
         "Request Status": observable<any>({
            property: "status_id",
            values: statusFilterOptions,
         }),
         Project: observable<any>({
            property: "project_id",
            values: Format.keyableSort(groupEntities.projectOptions || [], "name").map((item) =>
               item.value(item.id!),
            ),
         }),
         "Project Status": observable<any>({
            property: "project_status",
            values: [
               new ValueSet({ name: "Active", value: "active" }),
               new ValueSet({ name: "Pending", value: "pending" }),
               new ValueSet({ name: "Inactive", value: "inactive" }),
            ],
            type: "select",
         }),
      };

      if (groupEntities.positionOptions && groupEntities.positionOptions?.length > 0) {
         Object.assign(filterOptions, {
            "Job Titles": observable<any>({
               property: "position_id",
               values: groupEntities.positionOptions,
            }),
         });
      }

      const hasTags = (groupEntities.tagOptions?.length || 0) > 0;
      const hasCategorizedTags =
         (Object.keys(groupEntities.categorizedTagOptions || {})?.length || 0) > 0;

      if (hasTags) {
         Object.assign(filterOptions, {
            Tags: observable<any>({
               property: "tag_ids",
               type: "multi-select",
               values: Format.keyableSort(groupEntities.tagOptions || [], "name"),
            }),
         });
      } else if (hasCategorizedTags) {
         const categorizedTags = groupEntities.categorizedTagOptions || [];
         const classifiers = Object.keys(categorizedTags)
            .map((tagName) => {
               return { listLabel: tagName, chipLabel: null, value: tagName };
            })
            .sort((a, b) => a.listLabel.toLowerCase().localeCompare(b.listLabel.toLowerCase()));
         Object.assign(filterOptions, {
            Tags: observable<any>({
               property: "tag_ids",
               type: "multi-select",
               classifiers,
               classifierPaneName: "Tag Category",
               values: categorizedTags,
               backEnabled: true,
            }),
         });
      }

      if (authManager.companyModules()?.customFields && groupEntities.requestsCustomFieldFilters) {
         accumulateCustomFieldChipFilters(filterOptions, groupEntities.requestsCustomFieldFilters, {
            propertyName: "custom_fields",

            // Sensitive and financial fields don't yet exist on Requests.
            sensitiveFields: [],
            canViewSensitiveFields: true,
            canViewFinancials: true,
         });
      }

      return filterOptions;
   }

   private createColumnTemplates(): Array<ColumnTemplate<SerializedFindRequest>> {
      const formatOptionalTime = (time: number | null) => {
         if (time == null) return "";
         return DefaultStore.Data.TIME_OPTIONS.find((t) => t.value == time)?.name || "";
      };

      const templateVisitors: Array<{
         visit: () => boolean;
         accept: () => ColumnTemplate<SerializedFindRequest, unknown, unknown, any>; // TODO: Remove any.
      }> = [
         {
            visit: () => this.canManageRequests,
            accept: () => ({
               ...this.checkboxColumnGroupManager.columnGroup.columns[0],
               isFixed: true,
            }),
         },
         {
            visit: () => this.canManageRequests,
            accept: () => ({
               header: "",
               key: RequestsColumnKey.EDIT_REQUEST,
               width: 32,
               isFixed: true,
               ...EditActionCell.columnProviders({
                  isDisabled: (request) => {
                     return (
                        this.canManageRequests != true ||
                        (this.canManageOthersRequests != true &&
                           request.creator_id != authManager.authedUserId())
                     );
                  },
                  onClick: (request) => {
                     const observableRequest = observable(request);
                     const liveRequest = pureComputed(() => observableRequest());
                     this.onCreateOrEditRequest({
                        request: liveRequest,
                     });

                     const requestId = request.id;
                     this.store().rows.subscribe((rows) => {
                        const requestFromRow = rows.find((request) => request.id == requestId);
                        if (requestFromRow == null) {
                           modalManager.clearModal();
                        } else {
                           observableRequest(requestFromRow);
                        }
                     });
                  },
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? null
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => this.canManageAssignments,
            accept: () => ({
               header: "",
               key: RequestsColumnKey.FILL_REQUEST,
               width: 32,
               isFixed: true,
               ...FillRequestCell.columnProviders(),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().fillRequestEditorFactory({
                          requests: [row],
                          onSelect: (resource, request) =>
                             this.onFillRequest({
                                request: pureComputed(() => request),
                                person: resource,
                             }),
                       })
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => this.canManageRequests,
            accept: () => ({
               header: "",
               key: RequestsColumnKey.COMMENT_REQUEST,
               width: 32,
               isFixed: true,
               ...CommentActionCell.columnProviders((request) => ({
                  commentCount: observable(request.comments.length),
                  data: request,
                  isDisabled: () => {
                     return this.canViewRequestsNotes != true;
                  },
                  onClick: () => {
                     const observableRequest = observable(request);
                     const liveRequest = pureComputed(() => observableRequest());
                     this.onCommentRequest(liveRequest);

                     const requestId = request.id;
                     this.store().rows.subscribe((rows) => {
                        const requestFromRow = rows.find((request) => request.id == requestId);
                        if (requestFromRow == null) {
                           modalManager.clearModal();
                        } else {
                           observableRequest(requestFromRow);
                        }
                     });
                  },
               })),
               cursorStateProvider: () =>
                  this.canViewRequestsNotes
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED,
               editorFactory: ({ cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? null
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Project",
               key: RequestsColumnKey.PROJECT_NAME,
               width: ColumnWidthDefault.PROJECT_NAME,
               minWidth: ColumnWidthMin.PROJECT_NAME,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...(this.canViewProject
                  ? LinkedTextCell.columnProviders((data) => ({
                       text: data.project.name,
                       href: `/groups/${authManager.selectedGroupId()}/projects/${data.project.id}`,
                    }))
                  : TextCell.columnProviders((data) => data.project.name)),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().projectEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Project #",
               key: RequestsColumnKey.JOB_NUMBER,
               width: ColumnWidthDefault.PROJECT_NUMBER,
               minWidth: ColumnWidthMin.PROJECT_NUMBER,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) =>
                  request.project.job_number != null ? request.project.job_number : "",
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Job Title",
               key: RequestsColumnKey.POSITION_NAME,
               width: ColumnWidthDefault.JOB_TITLE,
               minWidth: ColumnWidthMin.JOB_TITLE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...ColorCircleTextCell.columnProviders((request) => {
                  const jobTitle = request.job_title;
                  return {
                     text: jobTitle?.name || "",
                     color: jobTitle?.color || null,
                  };
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().jobTitleEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Tags",
               key: RequestsColumnKey.TAGS,
               width: ColumnWidthDefault.TAGS,
               minWidth: ColumnWidthMin.TAGS,
               isDefault: true,
               isResizable: true,
               ...TagsCell.columnProviders((request) => {
                  return {
                     tagInstances: request.tags.map((tag) => {
                        return {
                           abbreviation: tag.abbreviation,
                           color: tag.color,
                           name: tag.name,
                           expirationState: TagExpirationState.NOT_EXPIRING_SOON,
                        };
                     }),
                  };
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().tagInstancesEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Status",
               key: RequestsColumnKey.STATUS,
               width: ColumnWidthDefault.STATUS,
               minWidth: ColumnWidthMin.STATUS,
               isDefault: true,
               isSortable: false,
               isResizable: true,
               ...ColorCircleTextCell.columnProviders((request) => {
                  return {
                     text: request.status?.name || "",
                     color: request.status?.color || null,
                  };
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().statusEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Category",
               key: RequestsColumnKey.COST_CODE_NAME,
               width: ColumnWidthDefault.CATEGORY,
               minWidth: ColumnWidthMin.CATEGORY,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.category?.name || ""),
               cursorStateProvider: (row) => {
                  if (!this.canEditRequest(row)) return GridCursorState.ACTIONABLE_DISABLED;
                  return row.project.categories.length > 0
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().costCodeEditorFactory([row])
                     : !this.canEditRequest(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_REQUEST
                     : DisabledEditor.create({
                          text: "Project does not have any categories.",
                       }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Subcategory",
               key: RequestsColumnKey.LABEL_NAME,
               width: ColumnWidthDefault.SUBCATEGORY,
               minWidth: ColumnWidthMin.SUBCATEGORY,
               isResizable: true,
               ...TextCell.columnProviders((assignment) => assignment.subcategory?.name || ""),
               cursorStateProvider: (row) => {
                  if (!this.canEditRequest(row)) return GridCursorState.ACTIONABLE_DISABLED;
                  return this.hasSubcategoryOptions(row)
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().costCodeLabelEditorFactory([row])
                     : !this.canEditRequest(row)
                     ? DISABLED_EDITOR_CANNOT_EDIT_REQUEST
                     : !row.category
                     ? DisabledEditor.create({
                          text: "Category must be set to update the subcategory.",
                       })
                     : DisabledEditor.create({
                          text: "Category does not have any subcategories.",
                       }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Start Date",
               key: RequestsColumnKey.START_DAY,
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return this.convertToFormattedDetachedDay(request.start_day);
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().startDayEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "End Date",
               key: RequestsColumnKey.END_DAY,
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return this.convertToFormattedDetachedDay(request.end_day);
               }),
               cursorStateProvider: this.cursorStateProvider,
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().endDayEditorFactory([row])
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Created By",
               key: RequestsColumnKey.CREATOR_NAME,
               width: 155,
               isDefault: true,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) =>
                  request.creator?.name != null ? formatName(request.creator.name) : "",
               ),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Created",
               key: RequestsColumnKey.CREATED_AT,
               width: ColumnWidthDefault.SHORT_DATE,
               minWidth: ColumnWidthMin.SHORT_DATE,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return this.convertToFormattedDetachedDay(request.created_at);
               }),
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "Start Time",
               key: RequestsColumnKey.START_TIME,
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return formatOptionalTime(request.start_time);
               }),
               cursorStateProvider: (row) => {
                  if (!this.canManageRequests) return GridCursorState.NON_ACTIONABLE;
                  return row.start_time != null && this.canEditRequest(row)
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().startTimeEditorFactory([row])
                     : this.canEditRequest(row)
                     ? DisabledEditor.create({
                          text: "Start Time cannot be changed when Percent Allocation is set.",
                       })
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "End Time",
               key: RequestsColumnKey.END_TIME,
               width: ColumnWidthDefault.TIME,
               minWidth: ColumnWidthMin.TIME,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return formatOptionalTime(request.end_time);
               }),
               cursorStateProvider: (row) => {
                  if (!this.canManageRequests) return GridCursorState.NON_ACTIONABLE;
                  return row.end_time != null && this.canEditRequest(row)
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().endTimeEditorFactory([row])
                     : this.canEditRequest(row)
                     ? DisabledEditor.create({
                          text: "End Time cannot be changed when Percent Allocation is set.",
                       })
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
         {
            visit: () => true,
            accept: () => ({
               header: "% Allocation",
               key: RequestsColumnKey.PERCENT_ALLOCATED,
               width: 110,
               minWidth: 85,
               isDefault: false,
               isSortable: true,
               isResizable: true,
               ...TextCell.columnProviders((request) => {
                  return request.percent_allocated ? `${request.percent_allocated}%` : "";
               }),
               cursorStateProvider: (row) => {
                  if (!this.canManageRequests) return GridCursorState.NON_ACTIONABLE;
                  return row.percent_allocated && this.canEditRequest(row)
                     ? GridCursorState.ACTIONABLE
                     : GridCursorState.ACTIONABLE_DISABLED;
               },
               editorFactory: ({ row, cursorState }) =>
                  cursorState == GridCursorState.ACTIONABLE
                     ? this.store().percentAllocatedEditorFactory([row])
                     : this.canEditRequest(row)
                     ? DisabledEditor.create({
                          text: "Percent Allocation cannot be changed when Start Time and End Time are set.",
                       })
                     : DISABLED_EDITOR_CANNOT_EDIT_OTHERS_REQUESTS,
            }),
         },
      ];
      return templateVisitors
         .filter((visitor) => visitor.visit())
         .map((visitor) => visitor.accept());
   }

   private reload(params: { startFromCurrentCursor: boolean }) {
      this.store(this.createStore(params));
      // This is an async control flow for the inital load
      // and then is useless past that.
      this.viewConfigured(true);
      this.selectedIds([]);
   }

   private createStore({ startFromCurrentCursor }: { startFromCurrentCursor: boolean }) {
      return new RequestList3GridStore({
         cacheKey: `${authManager.selectedGroupId()}-requests-3-list`,
         customFields: pureComputed(() => this.columnManager()!.entityCustomFields()),
         errorModalColumnGroups: this.createRowIdentifierColumnGroups(),
         queryParams: this.createQueryParams(),
         startFromCurrentCursor,
         tags: pureComputed(() => this.allTags()),
         columnManager: this.columnManager,
      });
   }

   private createQueryParamsLegacy(): GetFilteredRequestParams {
      return {
         filters: serializeLegacyFilters(this.filterChips()),
         sortBy: this.sortOrder().columnKey,
         sortAscending: this.sortOrder().direction === Order.ASCENDING,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
   }

   private createQueryParams(): FindRequestsPaginatedQueryParams {
      const search = this.searchQuery();
      return {
         filters: serializedFilters(this.filterChips(), "name"),
         group_id:
            authManager.selectedGroupId() != "my-groups"
               ? authManager.selectedGroupId()
               : undefined,
         limit: ITEMS_PER_REQUEST,
         sort_by: this.sortOrder().columnKey as FindRequestsSortBy,
         sort_direction: this.sortOrder().direction,
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
         ...(ValidationUtils.validateInput(search) ? { search } : {}),
      };
   }

   private convertToFormattedDetachedDay(day: number | null) {
      let detachedDay = day;
      if (detachedDay == null) return "";
      if (String(detachedDay).length > 8) {
         detachedDay = DateUtils.getDetachedDay(new Date(detachedDay));
      }
      return DateUtils.formatDetachedDay(detachedDay, defaultStore.getDateFormat());
   }

   private customFieldValueExtractor(request: SerializedFindRequest, columnHeader: ColumnHeader) {
      const fieldId = columnHeader.meta()?.field_id;
      return request.custom_fields.find((f) => f.field_id == fieldId)?.value ?? null;
   }

   private onSortOrderChanged(sortOrder: GridSortOrder) {
      router.updateUrlQueryParam(App.RouteName.REQUESTS_LIST, "sortBy", sortOrder.columnKey);
      router.updateUrlQueryParam(
         App.RouteName.REQUESTS_LIST,
         Order.ASCENDING,
         (sortOrder.direction == Order.ASCENDING).toString(),
      );
      this.reload({ startFromCurrentCursor: false });
   }

   showSaveViewModal = (): void => {
      // Not using query params since it's on legacy pattern still.
      const modal = new Modal();
      const pane = new SaveViewPane(App.PageName.REQUESTS_LIST, {
         search: this.searchQuery(),
         columnHeaders: this.columnManager()?.getActiveColumnHeaders(),
         filters: this.filterChips(),
         sortBy: this.sortOrder().columnKey,
         sortDirection: this.sortOrder().direction,
      });
      modal.setPanes([pane]);
      legacyModalManager.showModal(modal, null, { class: "save-view-modal" });
   };

   private onSearchQueryChanged(search: string | null) {
      if (ValidationUtils.validateInput(search)) {
         router.updateUrlQueryParam(
            App.RouteName.REQUESTS_LIST,
            "search",
            encodeURIComponent(search.trim().toLowerCase()),
         );
      } else {
         router.removeQueryParam(App.RouteName.REQUESTS_LIST, "search");
      }
   }
   private createRowIdentifierColumnGroups(): Array<GridColumnGroup<SerializedFindRequest>> {
      return createColumnGroupForEach(
         {
            header: "Project",
            key: RequestsColumnKey.PROJECT_NAME,
            width: 160,
            cellFactory: TextCell.factory((request) => request.project.name),
         },
         {
            header: "Job Title",
            key: RequestsColumnKey.POSITION_NAME,
            width: 140,
            ...ColorCircleTextCell.columnProviders((request) => {
               const jobTitle = request.job_title;
               return {
                  text: jobTitle?.name || "",
                  color: jobTitle?.color || null,
               };
            }),
         },
         {
            header: "Start Date",
            key: RequestsColumnKey.START_DAY,
            width: 70,
            cellFactory: TextCell.factory((request) =>
               this.convertToFormattedDetachedDay(request.start_day),
            ),
         },
         {
            header: "End Date",
            key: RequestsColumnKey.END_DAY,
            width: 70,
            cellFactory: TextCell.factory((request) =>
               this.convertToFormattedDetachedDay(request.end_day),
            ),
         },
      );
   }

   private hasSubcategoryOptions(row: SerializedFindRequest) {
      if (!row.category) return false;
      const category = row.project.categories.find((category) => category.id === row.category?.id);
      return category?.subcategories?.length ?? 0 > 0;
   }

   private cursorStateProvider = (request: SerializedFindRequest) => {
      if (!this.canManageRequests) return GridCursorState.NON_ACTIONABLE;
      return this.canEditRequest(request)
         ? GridCursorState.ACTIONABLE
         : GridCursorState.ACTIONABLE_DISABLED;
   };
}
