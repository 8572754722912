import ko from "knockout"
import template from "./project-qr-profile.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Flag } from "@/flags"

### Auth, Real-Time & Stores ###
import { defaultStore } from "@/stores/default-store"
import { ProjectStore } from "@/stores/project-store.core"

export class ProjectQrProfileViewModel extends PageContentViewModel
   constructor: (companyQrId, projectQrId) ->
      super(template(), "Project QR Profile")
      @companyQrId = companyQrId
      @projectQrId = projectQrId

      @errorMessage = ko.observable(null)

      @qrInfo = ko.observable()
      @logoUrl = "#{window.config.LC_DOMAIN}/images/procore-logo.png"

      @showLogo = Flag.ENABLE_WORKFORCE_PLANNING_V1

      @loadData()

   formatMsDate: (ms) ->
      return DateUtils.getShortNumericDate(new Date(ms), defaultStore.getDateFormat())

   formatTimeVal: (time) ->
      return DateUtils.formatTimeVal(time)

   formattedDay: (day) ->
      return DateUtils.formatDetachedDay(day, defaultStore.getDateFormat())

   formatPhone: (phone) ->
      return "#{phone.slice(0,2)}-#{phone.slice(2,5)}-#{phone.slice(5,8)}-#{phone.slice(8,12)}"

   getPhotoUrl: (baseUrl) ->
      fragments = baseUrl.split("upload/")
      url = "#{fragments[0]}upload/g_face,c_thumb,w_60,h_60/#{fragments[1]}"
      return url

   getArrayString: (vals) ->
      str = ''
      for val in vals
         str += "#{val}, "
      return str.slice(0, -2)

   loadData: ->
      try
         result = await ProjectStore.getProjectQrInfo(@projectQrId, @companyQrId).payload
         @qrInfo(result.data)
      catch Error
         console.log("Error", Error)
         err = if typeof Error.message == Object then JSON.parse(Error.message) else Error
         if err.detailCode == 'qrCompanyNotFound' or err.detailCode == 'projectQrDisabled'
            @errorMessage("QR profiles for Projects have been disabled by the admins of this account.")
         else if err.detailCode == 'qrModuleDisabled'
            @errorMessage("The QR Code module has been disabled for this account.")
         else if err.detailCode == 'qrEntityArchived'
            @errorMessage("The Project for this QR code has been removed.")
         else
            @errorMessage("Invalid Scan. The image may be corrupted")
