import template from "./assignment-history-report.pug"
import { DateUtils } from "@/lib/utils/date"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import { Url as UrlUtils } from "@/lib/utils/url"
import { Format as FormatUtils } from "@/lib/utils/format"
import LaunchDarklyClient from "@laborchart-modules/launch-darkly-browser";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { groupStore } from "@/stores/group-store"
import { defaultStore } from "@/stores/default-store"
import { ProjectStore } from "@/stores/project-store.core"
import { requestContext } from "@/stores/common/request-context"
import { ReportStore } from "@/stores/report-store.core"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { ListViewExportsPane } from "@/lib/components/popup/list-view-exports-pane"
import { SaveReportPane } from "@/lib/components/popup/save-report-pane"

### Models ###
import { ValueSet } from "@/models/value-set"
import {
   ReportType
} from "@laborchart-modules/common/dist/postgres/schemas/common/enums";

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"
import * as ko from "knockout"


export class AssignmentHistoryReportViewModel extends PageContentViewModel
   constructor: () ->
      super(template(), "Assignment History")
      @existingConfig = ko.observable(null)
      @existingReportOptions = ko.observableArray()
      @groupedReportOptions = ko.observable()
      @isMyGroups = ko.observable(false)
      @selectedExistingReport = ko.observable()
      authManager.selectedGroupId.subscribeChange(@handleGroupChange)

      @shouldUseJWTToken = LaunchDarklyClient.getFlagValue('use-jwt-auth')

      @entityTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Projects", AssignmentHistoryReportViewModel.EntityOption.PROJECTS)
         new SegmentedControllerItem("People", AssignmentHistoryReportViewModel.EntityOption.PEOPLE)
      ])
      @selectedEntityType = ko.observable(@entityTypeOptions()[0])

      @entityIsProjects = ko.pureComputed =>
         return @selectedEntityType().value() == AssignmentHistoryReportViewModel.EntityOption.PROJECTS

      @reportDisabled = ko.pureComputed =>
         if @selectedEntityType().value() == AssignmentHistoryReportViewModel.EntityOption.PROJECTS
            return !@selectedProject()?
         else
            return !@selectedPerson()?

      @projectOptions = ko.observableArray([])
      @selectedProject = ko.observable()

      @peopleOptions = ko.observableArray([])
      @selectedPerson = ko.observable()

      @selectedProject.subscribe (newVal) =>
         @selectedPerson(null) if newVal?

      @selectedPerson.subscribe (newVal) =>
         @selectedProject(null) if newVal?

      @nameFormatOptions = ko.observableArray([
         new SegmentedControllerItem("John Doe", AssignmentHistoryReportViewModel.NameFormat.FULL)
         new SegmentedControllerItem("J. Doe", AssignmentHistoryReportViewModel.NameFormat.LAST)
         new SegmentedControllerItem("John D.", AssignmentHistoryReportViewModel.NameFormat.FIRST)
      ])
      @selectedNameFormat = ko.observable(@nameFormatOptions()[0])

      # Column Display Options
      @appliedRoleOptions = ko.observableArray()
      @selectedRoleOptions = ko.observableArray()
      @showAssignmentDuration = ko.observable(true)
      @showAssignmentEnd = ko.observable(true)
      @showAssignmentEndTime = ko.observable(true)
      @showAssignmentStart = ko.observable(true)
      @showAssignmentStartTime = ko.observable(true)
      @showAssignmentStatus = ko.observable(true)
      @showCostCode = ko.observable(true)
      @showEmployeeName = ko.observable(true)
      @showEmployeeNumber = ko.observable(true)
      @showJobName = ko.observable(true)
      @showJobNumber = ko.observable(true)
      @showJobTitle = ko.observable(true)
      @showLabel = ko.observable(true)

      @exportPopupBuilder = =>
         return if @reportDisabled()
         pdfUrl = @buildDownloadUrl(AssignmentHistoryReportViewModel.FileType.PDF)
         csvUrl = @buildDownloadUrl(AssignmentHistoryReportViewModel.FileType.CSV)
         return new Popup "Exports", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new ListViewExportsPane(pdfUrl, csvUrl)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['list-view__popup--exports'],
            () -> return

      @exportFilesPopupWrapper = ko.observable({
         popupBuilder: @exportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @saveReportCallbacks = {
         handleSave: @handleReportSave
         handleSaveAs: @handleReportSaveAs
         handleDelete: @handleReportDelete
      }
      @saveReportPopupBuilder = =>
         return if @reportDisabled()
         existingReportId = @existingConfig()?.id or null
         existingReportName = @existingConfig()?.name or null
         return new Popup "Save", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new SaveReportPane(existingReportId, existingReportName, @saveReportCallbacks)],
            ['report-detail-page__toolbar-btn', 'icon-export--gray'], ['save-report-popup'],
            () -> return

      @saveReportPopupWrapper = ko.observable({
         popupBuilder: @saveReportPopupBuilder
         options: {triggerClasses: ['icon-export--gray']}
      })

      @loadData()

   handleReportSave: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name

      if reportData.role_sets?.length
         reportData['role_ids'] = reportData.role_sets.map (item) -> return item.id
      else
         reportData['role_ids'] = []

      delete reportData.role_sets

      if authManager.selectedGroupId() == null
         reportData['group_id'] = 'my-groups'
      else
         reportData['group_id'] = authManager.selectedGroupId()

      if @existingConfig()?
         try
            await ReportStore.updateAssignmentHistoryReportOptions({ params: { report_id: @existingConfig().id }, body: reportData }).payload;
         catch err
            return console.error("Error in assignment-history handleReportSave: ", err)
      else
         try
            result = await ReportStore.createAssignmentHistoryReportOptions({ body: reportData }).payload;
            newId = result.data.id
            newOption = new ValueSet({name: reportData.name, value: newId})
            @existingReportOptions.push(newOption)
            @selectedExistingReport(newOption)
            config = { id: newId }
            for key, val of reportData
               newKey = FormatUtils.toUnderscore(key)
               config[newKey] = val
            @existingConfig(config)
         catch err
            return console.error("Creation failed in assignment-history handleReportSave: ", err)

   handleReportSaveAs: (name) =>
      reportData = @buildReportData()
      reportData['name'] = name

      if reportData.role_sets?.length
         reportData['role_ids'] = reportData.role_sets.map (item) -> return item.id
      else
         reportData['role_ids'] = []

      delete reportData.role_sets

      if authManager.selectedGroupId() == null
         reportData['group_id'] = 'my-groups'
      else
         reportData['group_id'] = authManager.selectedGroupId()

      try
         result = await ReportStore.createAssignmentHistoryReportOptions({ body: reportData }).payload;
         newId = result.data.id
         newOption = new ValueSet({name: reportData.name, value: newId})
         @existingReportOptions.push(newOption)
         @selectedExistingReport(newOption)
         config = { id: newId }
         for key, val of reportData
            newKey = FormatUtils.toUnderscore(key)
            config[newKey] = val
         @existingConfig(config)
      catch err
         return console.error("Creation failed in assignment-history handleReportSaveAs: ", err)

   handleReportDelete: =>
      try
         await ReportStore.deleteReportOptions(@existingConfig().id).payload;
      catch err
         return console.error("Error in assignment-history handleReportDelete: ", err)

   handleExistingReportSelection: (option) =>
      if option.group?
         reportId = option.data.value()
      else
         reportId = option.value()

      try
         result = await ReportStore.getReportOptions(reportId).payload
         config = result.data
         @existingConfig(config)
         @processExistingConfig()
      catch err
         return console.error("Error in assignment-history handleExistingReportSelection: ", err)

   handleRoleSelection: (selected, role) =>
      if @selectedRoleOptions().indexOf(role) != -1
         @selectedRoleOptions.remove(role)
      else
         @selectedRoleOptions.push(role)

   buildDownloadUrl: (fileType) =>
      url = if @shouldUseJWTToken
            # we do not need baseUrl because this uses store.requestJson
            "/api/v3/reports/assignment-history/#{fileType}/signed-url?"
         else
            requestContext.baseUrl + "/api/v3/reports/assignment-history/#{fileType}?"

      params = UrlUtils.serializeParams(@buildReportData())
      url += params
      return url

   handleGroupChange: (newGroupId) =>
      @isMyGroups(newGroupId == 'my-groups')
      @clearGroupDependentProperties()
      @loadData()

   clearGroupDependentProperties: =>
      @existingConfig(null)
      @existingReportOptions([])
      @groupedReportOptions({})
      @selectedExistingReport(null)
      @appliedRoleOptions([])
      @selectedRoleOptions([])
      @projectOptions([])
      @selectedProject(null)
      @peopleOptions([])
      @selectedPerson(null)

   buildReportData: ->
      params = {
         time_string: DateUtils.formatDate(new Date(), defaultStore.getDateFormat())
         entity_type: @selectedEntityType().value()
         entity_id: if @entityIsProjects() then @selectedProject().id else @selectedPerson().value()
         name_format: @selectedNameFormat().value()
         show_assignment_start: @showAssignmentStart()
         show_assignment_end: @showAssignmentEnd()
         show_assignment_start_time: @showAssignmentStartTime()
         show_assignment_end_time: @showAssignmentEndTime()
         show_cost_code: @showCostCode()
         show_label: @showLabel()
         show_assignment_duration: @showAssignmentDuration()
         show_assignment_status: @showAssignmentStatus()
         role_sets: @selectedRoleOptions().map (item) ->
            return {
               id: item.value()
               role_name: item.name()
            }
         date_format: defaultStore.getDateFormat()
      }
      if @entityIsProjects()
         params['show_employee_name'] = @showEmployeeName()
         params['show_employee_number'] = @showEmployeeNumber()
         params['show_job_title'] = @showJobTitle()
         params['show_job_name'] = false
         params['show_job_number'] = false
      else
         params['show_employee_name'] = false
         params['show_employee_number'] = false
         params['show_job_title'] = false
         params['show_job_name'] = @showJobName()
         params['show_job_number'] = @showJobNumber()

      return params

   processExistingConfig: ->
      return unless @existingConfig()?
      # Clear out arrays to prevent dupes.
      @selectedRoleOptions([])
      config = @existingConfig()

      for option in @nameFormatOptions()
         if option.value() == config.name_format
            @selectedNameFormat(option)
            break

      for option in @entityTypeOptions()
         if option.value() == config.entity_type
            @selectedEntityType(option)
            break

      entityTypePeople = AssignmentHistoryReportViewModel.EntityOption.PEOPLE
      entityTypeProjects = AssignmentHistoryReportViewModel.EntityOption.PROJECTS

      if config.entity_type == entityTypeProjects
         for option in @projectOptions()
            if option.id == config.entity_id
               @selectedProject(option)
               break
      else if config.entity_type == entityTypePeople
         for option in @peopleOptions()
            if option.value() == config.entity_id
               @selectedPerson(option)
               break

      @showAssignmentStart(config.show_assignment_start)
      @showAssignmentEnd(config.show_assignment_end)
      @showAssignmentStartTime(config.show_assignment_start_time)
      @showAssignmentEndTime(config.show_assignment_end_time)
      @showCostCode(config.show_cost_code)
      @showLabel(config.show_label)
      @showAssignmentDuration(config.show_assignment_duration)
      @showJobName(config.show_job_name)
      @showJobNumber(config.show_job_number)
      @showEmployeeName(config.show_employee_name)
      @showEmployeeNumber(config.show_employee_number)
      @showJobTitle(config.show_job_title)
      @showAssignmentStatus(config.show_assignment_status)

      for option in @appliedRoleOptions()
         if config.role_ids.indexOf(option.value()) != -1
            option.selected(true)
            @selectedRoleOptions.push(option)

   loadData: ->
      groupStore.getGroupEntities authManager.selectedGroupId(), ['projects', 'people'], (err, data) =>
         return console.log "Error: ", err if err
         @projectOptions(data.projectOptions)
         @peopleOptions(data.peopleOptions)

      result = await ProjectStore.getProjectAppliedRoleOptions(authManager.selectedGroupId()).payload
      @appliedRoleOptions result.data.map (role) ->
         roleOption = new ValueSet({name: role.position_name, value: role.position_id})
         roleOption['selected'] = ko.observable(false)
         return roleOption
      @processExistingConfig()

      try
         resultDB = await ReportStore.getReportsByType(authManager.selectedGroupId(), ReportType.ASSIGNMENT_HISTORY).payload;
         reportOptions = resultDB.data.map (item) -> return new ValueSet(item)
         if authManager.selectedGroupId() == 'my-groups'
            @existingReportOptions([])
            groupedReportOptions = {}
            for option in reportOptions
               if groupedReportOptions[option.baggage().group_name]?
                  groupedReportOptions[option.baggage().group_name].push(option)
               else
                  groupedReportOptions[option.baggage().group_name] = [option]
            @groupedReportOptions(groupedReportOptions)
            @selectedExistingReport(null)
            @isMyGroups(true)
         else
            @existingReportOptions(reportOptions)
            @groupedReportOptions({})
            @selectedExistingReport(null)
            @isMyGroups(false)
      catch err
         return console.error("Error in assignment-history loadData: ", err)

AssignmentHistoryReportViewModel.NameFormat = {
   FULL: "full"
   LAST: "last"
   FIRST: "first"
}
AssignmentHistoryReportViewModel.FileType = {
   PDF: "pdf"
   CSV: "csv"
}
AssignmentHistoryReportViewModel.EntityOption = {
   PROJECTS: "project"
   PEOPLE: "person"
}