import type { BatchEditor } from "@/lib/components/batch-edit/batch-edit";
import type { ComponentArgs } from "@/lib/components/common";
import { ArrayDropDownPane } from "@/lib/components/drop-downs/panes/array-drop-down-pane";
import type { MultiSelectItem } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import { MultiSelectDropDownPane } from "@/lib/components/drop-downs/panes/multi-select-drop-down-pane";
import type { ColorPickerEditorParams } from "@/lib/components/editors/color-picker-editor/color-picker-editor";
import { ColorPickerEditor } from "@/lib/components/editors/color-picker-editor/color-picker-editor";
import type { CurrencyEditorParams } from "@/lib/components/editors/currency-editor/currency-editor";
import { CurrencyEditor } from "@/lib/components/editors/currency-editor/currency-editor";
import type { DetachedDayEditorParams } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import { DetachedDayEditor } from "@/lib/components/editors/detached-day-editor/detached-day-editor";
import type { DropDownEditorParams } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import { DropDownEditor } from "@/lib/components/editors/drop-down-editor/drop-down-editor";
import type {
   GroupsEditorParams,
   GroupsEditorUpdate,
} from "@/lib/components/editors/groups-editor/groups-editor";
import { GroupsEditor, GroupType } from "@/lib/components/editors/groups-editor/groups-editor";
import type { PercentEditorParams } from "@/lib/components/editors/percent-editor/percent-editor";
import { PercentEditor } from "@/lib/components/editors/percent-editor/percent-editor";
import type {
   ProjectRolesEditorProject,
   ProjectRolesEditorUpdate,
} from "@/lib/components/editors/project-roles-editor/project-roles-editor";
import { ProjectRolesEditor } from "@/lib/components/editors/project-roles-editor/project-roles-editor";
import type { TagInstancesEditorParams } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import { TagInstancesEditor } from "@/lib/components/editors/tag-instances-editor/tag-instances-editor";
import type { TextEditorParams } from "@/lib/components/editors/text-editor/text-editor";
import { TextEditor } from "@/lib/components/editors/text-editor/text-editor";
import type { TimeEditorParams } from "@/lib/components/editors/time-editor/time-editor";
import { TimeEditor } from "@/lib/components/editors/time-editor/time-editor";
import type { TimezoneEditorParams } from "@/lib/components/editors/timezone-editor/timezone-editor";
import { TimezoneEditor } from "@/lib/components/editors/timezone-editor/timezone-editor";
import { TextCell } from "@/lib/components/grid/cells/text-cell";
import type { GridColumnGroup } from "@/lib/components/grid/grid-column-group";
import type {
   KeysetGridStoreParams,
   ResponsePayload,
} from "@/lib/components/grid/keyset-grid-store";
import { KeysetGridStore } from "@/lib/components/grid/keyset-grid-store";
import { authManager } from "@/lib/managers/auth-manager";
import { customFieldUpdateRowApplier } from "@/lib/utils/custom-field-instance";
import { Format } from "@/lib/utils/format";
import { GridStoreRowsUpdater } from "@/lib/utils/grid-store/grid-store-rows-updater";
import { Attachment } from "@/models/attachment";
import type { CustomFieldMeta } from "@/models/column-header";
import { PermissionLevel } from "@/models/permission-level";
import type { StoreStreamResponse } from "@/stores/common/store.core";
import { ProjectStore } from "@/stores/project-store.core";
import { getAttachedDate, getDetachedDay } from "@laborchart-modules/common/dist/datetime";
import { ProjectStatus } from "@laborchart-modules/common/dist/rethink/schemas/enums/projects";
import type { CustomFieldInstance } from "@laborchart-modules/common/dist/rethink/schemas/mixins/has-custom-fields";
import type { SerializedTagInstance } from "@laborchart-modules/common/dist/rethink/serializers/tag-instance-serializer";
import type { BatchDeleteProjectsPayload } from "@laborchart-modules/lc-core-api/dist/api/projects/delete-project";
import type {
   FindProjectsPaginatedQueryParams,
   SerializedProjectListProject,
   SerializedProjectListTagInstance,
} from "@laborchart-modules/lc-core-api/dist/api/projects/find-projects";
import type {
   StreamProjectUpdatesPayload,
   UpdateProjectPayload,
} from "@laborchart-modules/lc-core-api/dist/api/projects/update-projects";
import type { AuthType } from "@laborchart-modules/lc-core-api/dist/api/shared";
import type { Observable, ObservableArray } from "knockout";
import { pureComputed } from "knockout";
import { observable, observableArray } from "knockout";

export interface ProjectsList2GridStoreParams
   extends KeysetGridStoreParams<FindProjectsPaginatedQueryParams> {
   errorModalColumnGroups: Array<GridColumnGroup<SerializedProjectListProject>>;
}

export class ProjectsList2GridStore extends KeysetGridStore<
   SerializedProjectListProject,
   FindProjectsPaginatedQueryParams
> {
   readonly totalPossible: Observable<number> = observable(0);

   private readonly errorModalColumnGroups: Array<GridColumnGroup<SerializedProjectListProject>>;
   private readonly rowsUpdater: GridStoreRowsUpdater<
      SerializedProjectListProject,
      StreamProjectUpdatesPayload<AuthType.SESSION>
   >;
   private readonly rowsRemover: GridStoreRowsUpdater<
      SerializedProjectListProject,
      BatchDeleteProjectsPayload
   >;

   constructor(params: ProjectsList2GridStoreParams) {
      super(params);
      this.errorModalColumnGroups = params.errorModalColumnGroups;
      this.rowsUpdater = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => ProjectStore.updateProjectsStream(update),
         errorModalColumnGroups: this.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
      this.rowsRemover = new GridStoreRowsUpdater({
         rows: this.rows,
         updateStreamProvider: (update) => ProjectStore.batchDelete(update),
         errorModalColumnGroups: params.errorModalColumnGroups,
         errorMessageProvider: () => null,
      });
   }

   protected async loadRows(
      query: FindProjectsPaginatedQueryParams,
   ): Promise<ResponsePayload<SerializedProjectListProject>> {
      const projectList = await ProjectStore.getProjectList(query).payload;
      this.totalPossible(projectList.pagination.total_possible);
      return projectList;
   }

   nameEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      return TextEditor.factory(() => ({
         title: "Name",
         width: 200,
         value: projects.length === 1 ? projects[0].name : null,
         isRequired: true,
         saveProvider: async (name) => {
            if (!name) return;
            await this.updateRows({
               projects,
               update: { name },
               rowApplier: (project) => ({
                  ...project,
                  name,
               }),
            });
         },
      }))(projects);
   };

   colorEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<ColorPickerEditorParams> => {
      return ColorPickerEditor.factory(() => ({
         title: "Color",
         value: projects.length == 1 ? projects[0].color : null,
         isRequired: true,
         saveProvider: async (color) => {
            if (!color) return;
            await this.updateRows({
               projects,
               update: { color },
               rowApplier: (project) => ({
                  ...project,
                  color,
               }),
            });
         },
      }))(projects);
   };

   jobNumberEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const currentValue = projects.length === 1 ? projects[0].job_number : null;
      return TextEditor.factory(() => ({
         title: "Project #",
         width: 160,
         value: currentValue,
         isClearable: projects.length !== 1 || currentValue != null,
         isRequired: false,
         saveProvider: async (jobNumber) => {
            await this.updateRows({
               projects,
               update: { job_number: jobNumber },
               rowApplier: (project) => ({
                  ...project,
                  job_number: jobNumber,
               }),
            });
         },
      }))(projects);
   };

   addressEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].address_1 ?? null : null;
      return TextEditor.factory(() => ({
         title: "Address",
         width: 250,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (address1) => {
            await this.updateRows({
               projects,
               update: { address_1: address1 },
               rowApplier: (project) => ({
                  ...project,
                  address_1: address1,
               }),
            });
         },
      }))(projects);
   };

   address2EditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].address_2 ?? null : null;
      return TextEditor.factory(() => ({
         title: "Address 2",
         width: 250,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (address2) => {
            await this.updateRows({
               projects,
               update: { address_2: address2 },
               rowApplier: (project) => ({
                  ...project,
                  address_2: address2,
               }),
            });
         },
      }))(projects);
   };

   cityEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].city_town ?? null : null;
      return TextEditor.factory(() => ({
         title: "City",
         width: 200,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (city) => {
            await this.updateRows({
               projects,
               update: { city_town: city },
               rowApplier: (project) => ({
                  ...project,
                  city_town: city,
               }),
            });
         },
      }))(projects);
   };

   stateEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].state_province ?? null : null;
      return TextEditor.factory(() => ({
         title: "State",
         width: 150,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (state) => {
            await this.updateRows({
               projects,
               update: { state_province: state },
               rowApplier: (project) => ({
                  ...project,
                  state_province: state,
               }),
            });
         },
      }))(projects);
   };

   postalEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].zipcode ?? null : null;
      return TextEditor.factory(() => ({
         title: "Postal",
         width: 150,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (postalCode) => {
            await this.updateRows({
               projects,
               update: { zipcode: postalCode },
               rowApplier: (project) => ({
                  ...project,
                  zipcode: postalCode,
               }),
            });
         },
      }))(projects);
   };

   countryEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].country ?? null : null;
      return TextEditor.factory(() => ({
         title: "Country",
         width: 150,
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         saveProvider: async (country) => {
            await this.updateRows({
               projects,
               update: { country: country },
               rowApplier: (project) => ({
                  ...project,
                  country,
               }),
            });
         },
      }))(projects);
   };

   tagInstancesEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TagInstancesEditorParams<SerializedProjectListProject>> => {
      return TagInstancesEditor.factory<SerializedProjectListProject>(() => ({
         title: "Tags",
         value: projects,
         isExpirationDayEnabled: false,
         attachmentsProvider: async (projectId: string, tagId: string) => {
            const result = await ProjectStore.getProjectTagInstances(projectId).payload;
            return (
               result.data
                  .find((tag) => tag.tag_id == tagId)
                  ?.attachments?.map((attachment) => {
                     return new Attachment(attachment);
                  }) || []
            );
         },
         saveProvider: async (update) => {
            await this.rowsUpdater.update({
               size: update.payload.length,
               updatePayload: update.payload,
               rowApplier: (project) => {
                  if (update.addedTagInstance != null && update.addedTag != null) {
                     const tagInstanceIndexToReplace = (project.tag_instances ?? []).findIndex(
                        (tagInstance) => tagInstance.tag_id == update.addedTagInstance!.tag_id,
                     );
                     const mergedTagAndTagInstance = {
                        ...update.addedTagInstance,
                        tag: update.addedTag,
                     } as SerializedProjectListTagInstance;
                     if (tagInstanceIndexToReplace != -1)
                        project.tag_instances!.splice(
                           tagInstanceIndexToReplace,
                           1,
                           mergedTagAndTagInstance,
                        );
                     else (project.tag_instances ?? []).push(mergedTagAndTagInstance);
                  }
                  if (update.removedTagId) {
                     const tagInstanceIndexToRemove = project.tag_instances?.findIndex(
                        (instance) => instance.tag_id == update.removedTagId,
                     );
                     if (tagInstanceIndexToRemove) {
                        project.tag_instances!.splice(tagInstanceIndexToRemove, 1);
                     }
                  }
                  return project;
               },
            });
         },
         recordTransformer: (project) => ({
            id: project.id,
            group_ids: new Set(project.group_ids),
            tagInstances: pureComputed(() => {
               this.rows(); // Included in order to update sync the record on the row with that in the tag instance editor.
               return (project.tag_instances ?? []).map(
                  (tagInstance) =>
                     ({
                        expr_date: tagInstance.expr_date,
                        id: tagInstance.id,
                        tag_id: tagInstance.tag_id,
                        attachment_ids: tagInstance.attachment_ids,
                     } as SerializedTagInstance),
               );
            }),
         }),
      }))(projects);
   };

   timezoneEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TimezoneEditorParams> => {
      return TimezoneEditor.factory(() => ({
         title: "Timezone",
         value: projects.length == 1 ? projects[0].timezone : null,
         saveProvider: async (timezone) => {
            await this.updateRows({
               projects,
               update: { timezone: timezone },
               rowApplier: (project) => ({
                  ...project,
                  timezone,
               }),
            });
         },
      }))(projects);
   };

   statusEditorFactory = (
      projects: SerializedProjectListProject[],
      isInlineEdit?: boolean,
   ): ComponentArgs<DropDownEditorParams<{ id: string; name: string }>> => {
      return DropDownEditor.factory(() => ({
         title: "Status",
         value: projects.length == 1 ? new Set([projects[0].status]) : new Set(),
         pane: new ArrayDropDownPane({
            items: Object.values(ProjectStatus).map((val) => ({
               id: val as string,
               name: Format.capitalize(val),
            })),
         }),
         cellFactory: TextCell.factory<{ id: string; name: string }>((item) => item.name),
         isRequired: true,
         saveProvider: async ([selected]) => {
            await this.updateRows({
               projects,
               update: { status: selected.id as ProjectStatus },
               rowApplier: (project) => ({
                  ...project,
                  status: selected.name as ProjectStatus,
               }),
            });
         },
         validators: isInlineEdit
            ? [
                 (value) => {
                    return value.has("active") && projects[0].start_date == null
                       ? { valid: false, error: "Active projects must have a Start Date." }
                       : { valid: true };
                 },
              ]
            : undefined,
      }))(projects);
   };

   dailyStartTimeEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TimeEditorParams> => {
      const isOne = projects.length === 1;
      return TimeEditor.factory(() => ({
         title: "Start Time",
         value: isOne ? projects[0].daily_start_time ?? null : null,
         isRequired: true,
         saveProvider: async (startTime) => {
            if (startTime == null) return;
            await this.updateRows({
               projects,
               update: { daily_start_time: startTime },
               rowApplier: (project) => ({
                  ...project,
                  daily_start_time: startTime,
               }),
            });
         },
      }))(projects);
   };

   dailyEndTimeEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TimeEditorParams> => {
      const isOne = projects.length === 1;
      return TimeEditor.factory(() => ({
         title: "End Time",
         value: isOne ? projects[0].daily_end_time ?? null : null,
         isRequired: true,
         saveProvider: async (endTime) => {
            if (endTime == null) return;
            await this.updateRows({
               projects,
               update: { daily_end_time: endTime },
               rowApplier: (project) => ({
                  ...project,
                  daily_end_time: endTime,
               }),
            });
         },
      }))(projects);
   };

   startDateEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      const value =
         projects.length == 1
            ? projects[0].start_date
               ? getDetachedDay(new Date(projects[0].start_date!))
               : null
            : null;
      return DetachedDayEditor.factory(() => ({
         title: "Start Date",
         value,
         isClearable: projects.length > 1 || value != null,
         saveProvider: async (startDay): Promise<void> => {
            const startDate = startDay ? getAttachedDate(startDay).getTime() : null;
            await this.updateRows({
               projects,
               // TODO: Clean up once start date is properly nullable.
               update: { start_date: startDate! },
               rowApplier: (project) => ({
                  ...project,
                  start_date: startDate,
               }),
            });
         },
      }))(projects);
   };

   endDateEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<DetachedDayEditorParams> => {
      const value =
         projects.length == 1
            ? projects[0].est_end_date
               ? getDetachedDay(new Date(projects[0].est_end_date!))
               : null
            : null;
      return DetachedDayEditor.factory(() => ({
         title: "Est. End Date",
         value,
         isClearable: projects.length > 1 || value != null,
         saveProvider: async (endDay): Promise<void> => {
            const estEndDate = endDay ? getAttachedDate(endDay).getTime() : null;
            await this.updateRows({
               projects,
               update: { est_end_date: estEndDate },
               rowApplier: (project) => ({
                  ...project,
                  est_end_date: estEndDate,
               }),
            });
         },
      }))(projects);
   };

   bidRateEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<CurrencyEditorParams> => {
      const isOne = projects.length === 1;
      return CurrencyEditor.factory(() => ({
         title: "Bid Rate",
         value: isOne ? projects[0].bid_rate ?? null : null,
         isRequired: false,
         minVal: 0,
         saveProvider: async (bidRate) => {
            await this.updateRows({
               projects,
               update: { bid_rate: bidRate },
               rowApplier: (project) => ({
                  ...project,
                  bid_rate: bidRate,
               }),
            });
         },
      }))(projects);
   };

   customerNameEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const currentValue = projects.length === 1 ? projects[0].customer_name ?? null : null;
      return TextEditor.factory(() => ({
         title: "Customer",
         width: 200,
         value: currentValue,
         isClearable: projects.length !== 1 || currentValue != null,
         isRequired: false,
         saveProvider: async (customerName) => {
            await this.updateRows({
               projects,
               update: { customer_name: customerName },
               rowApplier: (project) => ({
                  ...project,
                  customer_name: customerName,
               }),
            });
         },
      }))(projects);
   };

   percentCompleteEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<PercentEditorParams> => {
      const isOne = projects.length === 1;
      const currentValue = isOne ? projects[0].percent_complete ?? null : null;
      return PercentEditor.factory(() => ({
         title: "Percent Complete",
         value: currentValue,
         isClearable: isOne === false || currentValue != null,
         isRequired: false,
         validators: [
            (percent) => {
               return percent != null && percent < 0
                  ? {
                       status: false,
                       message: "Percent Complete must be greater than or equal to 0.",
                    }
                  : {
                       status: true,
                       message: null,
                    };
            },
         ],
         saveProvider: async (percentComplete) => {
            await this.updateRows({
               projects,
               update: { percent_complete: percentComplete },
               rowApplier: (project) => ({
                  ...project,
                  percent_complete: percentComplete,
               }),
            });
         },
      }))(projects);
   };

   projectTypeEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<TextEditorParams> => {
      const currentValue = projects.length === 1 ? projects[0].project_type ?? null : null;
      return TextEditor.factory(() => ({
         title: "Project Type",
         width: 200,
         value: currentValue,
         isClearable: projects.length !== 1 || currentValue != null,
         isRequired: false,
         saveProvider: async (projectType) => {
            await this.updateRows({
               projects,
               update: { project_type: projectType },
               rowApplier: (project) => ({
                  ...project,
                  project_type: projectType,
               }),
            });
         },
      }))(projects);
   };

   groupsInlineEditorFactory(
      projects: SerializedProjectListProject[],
   ): ComponentArgs<DropDownEditorParams<any>> {
      const project = projects[0];
      const visibleGroupIds = authManager.getContextAccessibleGroupIds();
      const groupNames = authManager.companyGroupNames();
      return DropDownEditor.factory(() => {
         const value = new Set(project.group_ids);
         const selectedIds = observable(value ? value : new Set<string>());
         const pane = new MultiSelectDropDownPane({
            items: Object.entries(groupNames)
               .map(([key, val]) => ({ id: key, name: val }))
               .filter((group) => visibleGroupIds.has(group.id) || value.has(group.id))
               .sort((left, right) =>
                  !visibleGroupIds.has(left.id) && visibleGroupIds.has(right.id)
                     ? 1
                     : left.name.localeCompare(right.name),
               ),
            selectedIds,
            textProvider: (item) => item.name,
            isDisabledItem: (item) => !visibleGroupIds.has(item.id),
         }) as any;
         const params: DropDownEditorParams<MultiSelectItem<{ id: string; name: string }>> = {
            title: "Groups",
            pane,
            isRequired: true,
            cellFactory: pane.cellFactory,
            selectedItemCellFactory: TextCell.factory<
               MultiSelectItem<{ id: string; name: string }>
            >((item) => {
               return (item.item as { id: string; name: string }).name;
            }),
            actionInterceptor: pane.actionInterceptor,
            saveProvider: async (update) => {
               const selectedGroupIds = new Set(
                  Array.from(update)
                     .map((v) => {
                        return typeof v.item == "symbol" ? null : v.item.id;
                     })
                     .filter((id) => id != null),
               ) as Set<string>;
               const groupIdUpdateRecord = Array.from(visibleGroupIds)
                  .map((id) => ({ [id]: selectedGroupIds.has(id) }))
                  .reduce((acc, cur) => ({ ...acc, ...cur }), {});
               await this.updateRows({
                  projects,
                  update: {
                     group_ids: groupIdUpdateRecord,
                  },
                  rowApplier: (project) => {
                     const ids = Array.from(
                        new Set([...project.group_ids, ...selectedGroupIds]),
                     ).filter((id) => groupIdUpdateRecord[id] != false);
                     if (!ids.some((id) => authManager.isVisibleGroupId(id))) {
                        // Remove row by returning null.
                        return null;
                     }
                     return {
                        ...project,
                        group_ids: ids,
                     };
                  },
               });
            },
            selectionDescriptionProvider: () =>
               selectedIds().size > 1 ? `${selectedIds().size} Selected` : null,
            value: pane.actionableSelectedIds,
            validators: [
               (value) => {
                  return value.size == 0 &&
                     !project.group_ids.some((id) => !visibleGroupIds.has(id))
                     ? { valid: false, error: `Groups are required.` }
                     : { valid: true };
               },
            ],
         };
         return params;
      })(projects);
   }

   groupsBatchEditorFactory = (
      projects: SerializedProjectListProject[],
   ): ComponentArgs<GroupsEditorParams<SerializedProjectListProject>> => {
      const validator = (
         project: SerializedProjectListProject,
         groupChange: GroupsEditorUpdate,
      ) => {
         if (
            project.group_ids.every((groupId) => groupChange[groupId] == false) &&
            project.group_ids.length > 0
         )
            return "Cannot remove a project from all groups.";
         return null;
      };

      return GroupsEditor.factory<SerializedProjectListProject>(() => ({
         title: "Groups",
         groupType: GroupType.GROUP_IDS,
         value: projects.filter(
            (p) => p.group_ids.some(authManager.isVisibleGroupId) || p.group_ids.length == 0,
         ),
         isRequired: true,
         selectedItemCellFactory: TextCell.factory(
            (item: MultiSelectItem<{ id: string; name: string }>) => {
               return (item.item as { id: string; name: string }).name;
            },
         ),
         getRecordGroupIds: (project) => new Set(project.group_ids),
         isAccessibleGroupId: authManager.isAccessibleGroupId,
         isVisibleGroupId: authManager.isVisibleGroupId,
         setRecordGroupIds: (project, groupIds) => {
            project.group_ids = [...new Set([...groupIds, ...project.group_ids])];
            return project;
         },
         validator,
         conflictModalColumnGroups: this.errorModalColumnGroups,
         saveProvider: async (value) => {
            const validProjects = projects.filter((p) => validator(p, value) == null);
            await this.updateRows({
               projects: validProjects,
               update: { group_ids: value },
               rowApplier: (project) => {
                  if (project == null) {
                     // Remove row by returning null.
                     return null;
                  }

                  const addedIds = Object.entries(value)
                     .filter(([_, included]) => included)
                     .map(([id]) => id);
                  const ids = Array.from(new Set([...project.group_ids, ...addedIds])).filter(
                     (id) => value[id] != false,
                  );

                  // Handle removal of any rows that are no longer visible in the current view.
                  if (
                     addedIds.length == 0 &&
                     ids.every((id) => !authManager.isVisibleGroupId(id))
                  ) {
                     // Remove row by returning null.
                     return null;
                  }
                  project.group_ids = ids;
                  return project;
               },
            });
         },
      }))(projects);
   };

   batchEditFields(
      isEditable: (property: string) => boolean,
   ): Array<BatchEditor<SerializedProjectListProject>> {
      const fields: Array<BatchEditor<SerializedProjectListProject> & { isEnabled: boolean }> = [
         {
            factory: this.nameEditorFactory,
            isEnabled: isEditable("name"),
         },
         {
            factory: this.colorEditorFactory,
            isEnabled: isEditable("color"),
         },
         {
            factory: this.jobNumberEditorFactory,
            isEnabled: isEditable("job_number"),
         },
         {
            factory: this.addressEditorFactory,
            isEnabled: isEditable("address_1"),
         },
         {
            factory: this.address2EditorFactory,
            isEnabled: isEditable("address_2"),
         },
         {
            factory: this.cityEditorFactory,
            isEnabled: isEditable("city_town"),
         },
         {
            factory: this.stateEditorFactory,
            isEnabled: isEditable("state_province"),
         },
         {
            factory: this.postalEditorFactory,
            isEnabled: isEditable("zipcode"),
         },
         {
            factory: this.countryEditorFactory,
            isEnabled: isEditable("country"),
         },
         {
            factory: (projects) => {
               const projectsObservable = observableArray(
                  projects.map((project) => ({
                     id: project.id,
                     groupIds: new Set(project.group_ids),
                     roles: (project.roles || []).map((role) => ({
                        id: role.id,
                        position_id: role.job_title_id,
                        assignee_name: role.assignee_name,
                        assignee_id: role.person_id,
                     })),
                  })),
               );
               return ProjectRolesEditor.factory<SerializedProjectListProject>(() => ({
                  title: "Roles",
                  value: projectsObservable,
                  saveProvider: async (update) => {
                     await this.updateProjectRoles(projectsObservable, update);
                  },
               }))(projects);
            },
            hasInternalSaveManagement: true,
            isEnabled: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_ROLES),
         },
         {
            factory: this.tagInstancesEditorFactory,
            hasInternalSaveManagement: true,
            isEnabled: authManager.checkAuthAction(PermissionLevel.Action.EDIT_PROJECT_TAGS),
         },
         {
            factory: this.statusEditorFactory,
            validator: (project, value) => {
               const selectedStatus = value.values().next().value;
               if (selectedStatus.id == "active" && project.start_date == null) {
                  return "Active projects must have a Start Date.";
               }
               return null;
            },
            isEnabled: isEditable("status"),
         },
         {
            factory: this.dailyStartTimeEditorFactory,
            isEnabled: isEditable("daily_start_time"),
         },
         {
            factory: this.dailyEndTimeEditorFactory,
            isEnabled: isEditable("daily_end_time"),
         },
         {
            factory: this.timezoneEditorFactory,
            isEnabled: isEditable("timezone"),
         },
         {
            factory: this.startDateEditorFactory,
            isEnabled: isEditable("start_date"),
         },
         {
            factory: this.endDateEditorFactory,
            isEnabled: isEditable("est_end_date"),
         },
         {
            factory: this.bidRateEditorFactory,
            isEnabled: isEditable("bid_rate"),
         },
         {
            factory: this.customerNameEditorFactory,
            isEnabled: isEditable("customer_name"),
         },
         {
            factory: this.percentCompleteEditorFactory,
            isEnabled: isEditable("percent_complete"),
         },
         {
            factory: this.projectTypeEditorFactory,
            isEnabled: isEditable("type"),
         },
         {
            factory: this.groupsBatchEditorFactory,
            isEnabled: isEditable("group_ids"),
            hasInternalSaveManagement: true,
         },
      ];
      return fields.filter((field) => field.isEnabled).map(({ isEnabled, ...rest }) => rest);
   }

   async updateCustomFields(
      projects: SerializedProjectListProject[],
      customFieldMeta: CustomFieldMeta,
      value: CustomFieldInstance["value"] | null,
   ): Promise<void> {
      await this.updateRows({
         projects,
         update: {
            custom_fields: {
               [customFieldMeta.field_id]: value,
            },
         },
         rowApplier: (project) => {
            return customFieldUpdateRowApplier({
               customFieldMeta,
               row: project,
               value,
            });
         },
      });
   }

   async updateProjectRoles(
      projects: ObservableArray<ProjectRolesEditorProject>,
      update: ProjectRolesEditorUpdate,
   ): Promise<void> {
      await this.rowsUpdater.update({
         size: update.payload.length,
         updatePayload: update.payload,
         rowApplier: (project: SerializedProjectListProject) => {
            const roles = (project.roles || []).map(
               ({ id, assignee_name, person_id, job_title_id }) => ({
                  id,
                  assignee_name,
                  assignee_id: person_id,
                  position_id: job_title_id,
               }),
            );
            let updatedRoles = roles.concat();
            if (update.added) {
               const added = update.added;
               const hasRoleAlready = roles.some((role) => {
                  return (
                     role.assignee_id == added.assignee_id && role.position_id == added.position_id
                  );
               });
               updatedRoles = updatedRoles
                  .filter((role) => {
                     if (role.position_id != added.position_id) return true;
                     if (role.assignee_id == added.assignee_id) return true;
                     return !update.isReplace;
                  })
                  .concat(hasRoleAlready ? [] : [added]);
            }
            if (update.removed) {
               const removed = update.removed;
               updatedRoles = updatedRoles.filter((role) => {
                  return !(
                     role.assignee_id == removed.assignee_id &&
                     role.position_id == removed.position_id
                  );
               });
            }

            // Update the project in the observable shared with the editor.
            const index = projects().findIndex((p) => p.id == project.id);
            if (index != -1) {
               projects.splice(index, 1, {
                  id: project.id,
                  groupIds: new Set(project.group_ids),
                  roles: updatedRoles,
               });
            }
            return {
               ...project,
               roles: updatedRoles.map((role) => ({
                  ...role,
                  person_id: role.assignee_id,
                  job_title_id: role.position_id,
                  assignee_name: role.assignee_name,
               })),
            };
         },
      });
   }

   private async updateRows({
      projects,
      update,
      rowApplier,
   }: {
      projects: SerializedProjectListProject[];
      update: Partial<UpdateProjectPayload<AuthType.SESSION>>;
      rowApplier: (project: SerializedProjectListProject) => SerializedProjectListProject | null;
   }) {
      await this.rowsUpdater.update({
         size: projects.length,
         updatePayload: projects.map((p) => ({ id: p.id, ...update })),
         rowApplier,
      });
   }

   async batchDeleteRows(assignments: SerializedProjectListProject[]): Promise<string[]> {
      const ids = assignments.map((a) => a.id);
      await this.rowsRemover.delete({ ids });
      this.totalPossible(this.totalPossible() - assignments.length);
      return ids;
   }

   createLoadAllRowsStream(
      queryParams: FindProjectsPaginatedQueryParams,
   ): StoreStreamResponse<SerializedProjectListProject> {
      return ProjectStore.findProjectListStream({
         filters: queryParams.filters,
         sort_direction: queryParams.sort_direction,
         group_id: queryParams.group_id,
         sort_by: queryParams.sort_by,
         timezone: queryParams.timezone,
         starting_after: queryParams.starting_after,
         starting_at: queryParams.starting_at,
         starting_before: queryParams.starting_before,
         search: queryParams.search,
         ...(queryParams.custom_field_id ? { custom_field_id: queryParams.custom_field_id } : {}),
      });
   }
}
