import "./totals.styl"
import template from "./totals.pug"
import { App } from "@/views/app"
import { Format as FormatUtils } from "@/lib/utils/format"
import { DateUtils } from "@/lib/utils/date"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import * as BrowserStorageUtils from "@/lib/utils/browser-storage"
import { buildDateFilterInstance, } from '@/lib/utils/chip-filter-helper'
import { accumulateCustomFieldChipFilters } from "@/lib/utils/custom-field-helper"

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { TotalsStore } from "@/stores/totals-store.core"
import { groupStore } from "@/stores/group-store"
import { SavedViewStore } from "@/stores/saved-view-store.core"

### Mediators ###
import { PageableLoadingMediator } from "@/lib/mediators/pageable-loading-mediator"
import { TotalsChartMediator } from "@/lib/mediators/totals-chart-mediator"
import { ChipFilterMediator } from "@/lib/mediators/chip-filter-mediator"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { TotalsConfigPane } from "@/lib/components/popup/totals-config-pane"

### Modals ###
import { modalManager } from "@/lib/managers/modal-manager"
import { Modal } from "@/lib/components/modals/modal"
import { TotalsExportModalPane } from "@/lib/components/modals/totals-export-modal-pane"
import { SaveViewPane } from "@/lib/components/modals/save-view-pane/save-view-pane"

### Models ###
import { ValueSet } from "@/models/value-set"
import { PermissionLevel } from "@/models/permission-level"

### Framework Includes ###
import ko from "knockout"
import $ from "jquery"

import LaunchDarklyBrowser from "@laborchart-modules/launch-darkly-browser"

export class TotalsViewModel extends PageContentViewModel
   constructor: (queryParams) ->
      super(template(), "Totals")

      @resourcesGroupFilterFlag = ko.pureComputed(() => LaunchDarklyBrowser.getFlagValue("totals-job-titles-group-filter"))

      # TODO: Need to clean up view when this is disabled. Not going to be frequent.
      @canViewRequests = authManager.checkAuthAction(PermissionLevel.Action.VIEW_REQUESTS)
      @canViewTimeOff= authManager.checkAuthAction(PermissionLevel.Action.VIEW_TIME_OFF)
      @canViewProjectFinancials = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_FINANCIALS)
      @allowExportingData = authManager.checkAuthAction(PermissionLevel.Action.ALLOW_EXPORTING_DATA)
      @tagCategoriesEnabled = authManager.companyModules()?.tagCategories
      @itemsPerRequest = 150

      @selectedRangeDays = ko.observable(91)

      @disposableViewStartBlock = false
      rawToday = new Date()
      sanitizedToday = new Date(rawToday.getFullYear(), rawToday.getMonth(), rawToday.getDate())
      @viewStartDate = ko.observable(sanitizedToday)

      @viewStartDay = ko.pureComputed =>
         return DateUtils.getDetachedDay(@viewStartDate())

      @totalsChartMediator = new TotalsChartMediator()
      @chipFilterMediator = new ChipFilterMediator()
      @loadingMediator = new PageableLoadingMediator()

      @projectQuery = ko.observable()
      @labeledFilterOptions = ko.observable()
      @defaultChips = TotalsViewModel.DEFAULT_FILTER_CHIPS

      @filterChips = ko.observableArray(TotalsViewModel.DEFAULT_FILTER_CHIPS)

      @rangeTitle = ko.pureComputed =>
         return switch @selectedRangeDays()
            when 7 then "1 Week"
            when 14 then "2 Weeks"
            when 28 then "4 Weeks"
            when 56 then "8 Weeks"
            when 91 then "13 Weeks"
            when 182 then "6 Months"
            when 364 then "1 Year"
            when 730 then "2 Years"
            when 1095 then "3 Years"

      @totalPossible = ko.observable(null)
      @totalsData = ko.observable(null)
      @exportsDisabled = ko.pureComputed => @totalsData()?.length == 0
      @pageDrawLimit = 25
      @showingPager = ko.pureComputed =>
         return false unless @totalsData()?.breakdownData?
         return @totalsData().breakdownData.length > @pageDrawLimit

      @pageIndex = ko.observable(0)
      @pageIndex.subscribe (newVal) =>
         if newVal?
            # Doing this to try to provide visual hint that button click worked.
            $("#totals-wrapper").empty()
            @handleRedraw(newVal * @pageDrawLimit)

      @currentRequest = ko.observable()

      @pagableResultCount = ko.pureComputed =>
         return 0 unless @totalsData()?.breakdownData?
         return @totalsData().breakdownData.length

      @currentActiveResourceCount = ko.observable()
      @currentActiveResourceTitle = ko.pureComputed =>
         return '' unless @currentActiveResourceCount()?
         return "Active Resources: #{@currentActiveResourceCount()}"

      @viewConfig = ko.observable(TotalsViewModel.VIEW_DEFAULTS)

      @defaultOptions = {
         startDate: @viewStartDate(),
         days: @selectedRangeDays(),
         totalsUnits: @viewConfig().totalsUnits,
         viewBy: @viewConfig().viewBy,
         activeResources: @currentActiveResourceCount(),
         showJobNumbers: @viewConfig().showJobNumbers,
         showCategories: @viewConfig().showCategories,
         showSubCategories: @viewConfig().showSubCategories
         permissions: {
            canViewRequests: @canViewRequests
            canViewProjectFinancials: @canViewProjectFinancials
            canViewPeopleFinancials: @canViewPeopleFinancials
            canViewTimeOff: @canViewTimeOff
         }
      }

      @groupIdSubscription = authManager.selectedGroupId.subscribeChange(@handleGroupChange)

      @noResultsFound = ko.observable(false)
      @loadingData = ko.observable(false)
      @loadingDepth = ko.observable(0)
      @loadingHtmlText = ko.pureComputed =>
         return '' unless @loadingData() 
         if @totalPossible()?
            return "Processing <span class='totals-loading-message__count'>#{@loadingDepth()}</span> / <span class='totals-loading-message__count'>#{@totalPossible()}</span> projects for assignment information. This may take a moment..."
         else
            return "Processing your matching projects for assignment information. This may take a moment..."
      @progressPercentage = ko.pureComputed =>
         return 0 unless @loadingData() and @totalPossible()?
         return (@loadingDepth() / @totalPossible()) * 100

       if queryParams?.viewId?
         @loadSavedView(queryParams.viewId)
      else
         # Load standard view.
         @setupStandardView(queryParams)

         @makeInitialLoadCalls()

      # Popups
      @rangePopupBuilder = =>
         handleRangeSelection = (days) =>
            @selectedRangeDays(days)

         items = [
            new PopupListItem({title: "1 Week", clickCallback: handleRangeSelection, callbackData: 7})
            new PopupListItem({title: "2 Weeks", clickCallback: handleRangeSelection, callbackData: 14})
            new PopupListItem({title: "4 Weeks", clickCallback: handleRangeSelection, callbackData: 28})
            new PopupListItem({title: "8 Weeks", clickCallback: handleRangeSelection, callbackData: 56})
            new PopupListItem({title: "13 Weeks", clickCallback: handleRangeSelection, callbackData: 91})
            new PopupListItem({title: "6 Months", clickCallback: handleRangeSelection, callbackData: 182})
            new PopupListItem({title: "1 Year", clickCallback: handleRangeSelection, callbackData: 364})
            new PopupListItem({title: "2 Years", clickCallback: handleRangeSelection, callbackData: 730})
            new PopupListItem({title: "3 Years", clickCallback: handleRangeSelection, callbackData: 1095})
         ]
         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new PopupListPane("", items, {showArrows: false})],
            ['totals-toolbar__range-selector', 'totals-toolbar__range-title'], ["totals-chart__popup--range"])

      @rangePopupWrapper = {
         popupBuilder: @rangePopupBuilder
         options: {triggerClasses: ['totals-toolbar__range-selector']}
      }

      @totalsConfigPopupBuilder = =>
         handleApplyConfig = (config) =>
            if config?
               @viewConfig(config)
            else
               # Set defaults
               @viewConfig(TotalsViewModel.VIEW_DEFAULTS)

            key = TotalsViewModel.BrowserStorageKeys.TOTALS_VIEW_CONFIG
            BrowserStorageUtils.storeJsonValue(key, config)

            @loadTotals()

         return new Popup("", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_LEFT,
            [new TotalsConfigPane(@viewConfig(), handleApplyConfig)],
            ['totals-toolbar__project-config', 'totals-toolbar__text-btn__text'], ["totals__config-popup"])

      @totalsConfigPopupWrapper = {
         popupBuilder: @totalsConfigPopupBuilder
         options: {triggerClasses: ['totals-toolbar__view-config-btn']}
      }

   makeInitialLoadCalls: =>
      if @loadingMediator.isInitialized()
         @loadData()
      else
         @loadingMediator.isInitialized.subscribe () =>
            @loadData()
      
      @setupViewConfigSubscriptions()

   setupStandardView: (queryParams) =>
      if queryParams?.range?
         rangeVal = Number(queryParams.range)
         if TotalsViewModel.VALID_RANGE_OPTIONS.indexOf(rangeVal) != -1
            @selectedRangeDays(rangeVal)
         else
            router.updateUrlQueryParam(App.RouteName.TOTALS, "range", 91)
      
      dayFilter = Number( queryParams?.dayFilter)
      if  21000001 >= dayFilter >= 19000001
         newDate = DateUtils.getAttachedDate(Number(queryParams.dayFilter))
         @viewStartDate(newDate)
      else
         rawToday = new Date()
         sanitizedToday = new Date(rawToday.getFullYear(), rawToday.getMonth(), rawToday.getDate())
         @viewStartDate(sanitizedToday)
         @setViewStartDateToStartOfPeriod_()
         @disposableViewStartBlock = false
      
      storedChips = BrowserStorageUtils.getPageFilterChips(TotalsViewModel.BrowserStorageKeys.TOTALS_FILTER_CHIPS)
      if storedChips?
         @filterChips(storedChips)
         setTimeout( () =>
            @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
         , 0)
      
      savedViewConfig = BrowserStorageUtils.getJsonValue(TotalsViewModel.BrowserStorageKeys.TOTALS_VIEW_CONFIG)
      if savedViewConfig?
         @viewConfig(savedViewConfig)

   setupViewConfigSubscriptions: ->
      @selectedRangeDays.subscribe(@handleViewingRangeChanged_)

      @viewStartDate.subscribe(@handleViewingDateChanged_)

      @filterChips.subscribe (newVal) =>
         return @disposableChipFilterBlock = false if @disposableChipFilterBlock
         BrowserStorageUtils.storePageFilterChips(newVal, TotalsViewModel.BrowserStorageKeys.TOTALS_FILTER_CHIPS)
         @loadTotals()

   setViewStartDateToStartOfPeriod_: () =>
      currentDate = new Date(@viewStartDate()?.getTime() or Date.now())
      if 91 <= @selectedRangeDays() < 182
         currentDate.setDate(currentDate.getDate() - currentDate.getDay());
         @disposableViewStartBlock = true
         @viewStartDate(currentDate)
      else if 182 <= @selectedRangeDays()
         currentDate.setDate(1)
         @disposableViewStartBlock = true
         @viewStartDate(currentDate)

   handleViewingDateChanged_: () =>
      return @disposableViewStartBlock = false if @disposableViewStartBlock
      @setViewStartDateToStartOfPeriod_()
      dayFilter = DateUtils.getDetachedDay(@viewStartDate())
      router.updateUrlQueryParam(App.RouteName.TOTALS, "dayFilter", dayFilter)
      @loadTotals()

   handleViewingRangeChanged_: (newValue) =>
      return @disposableViewStartBlock = false if @disposableViewStartBlock
      @setViewStartDateToStartOfPeriod_()
      dayFilter = DateUtils.getDetachedDay(@viewStartDate())
      router.updateUrlQueryParam(App.RouteName.TOTALS, "dayFilter", dayFilter)
      router.updateUrlQueryParam(App.RouteName.TOTALS, "range", newValue)
      @loadTotals()

   handleRedraw: (sliceStart) =>
      @setViewStartDateToStartOfPeriod_()
      options = {
         startDate: @viewStartDate(), 
         days: @selectedRangeDays(),
         totalsUnits: @viewConfig().totalsUnits,
         viewBy: @viewConfig().viewBy,
         activeResources: @currentActiveResourceCount()
         sliceStart: sliceStart or 0
         pageDrawLimit: @pageDrawLimit
         showJobNumbers: @viewConfig().showJobNumbers
         showCategories: @viewConfig().showCategories
         showSubCategories: @viewConfig().showSubCategories
         permissions: {
            canViewRequests: @canViewRequests
            canViewProjectFinancials: @canViewProjectFinancials
            canViewPeopleFinancials: @canViewPeopleFinancials
            canViewTimeOff: @canViewTimeOff
         }
      }
      if sliceStart?
         @totalsChartMediator.partialRedraw(@totalsData(), options)
      else
         @totalsChartMediator.redraw(@totalsData(), options)

   handleGroupChange: (newGroupId, oldGroupId) =>
      return unless newGroupId?
      if oldGroupId?
         @loadData()

   createBasePayload: () =>
      return {
         start_day: @viewStartDay()
         number_of_days: @selectedRangeDays()
         filters: @getFilterParams()
         search: @projectQuery()
         totals_unit: @viewConfig().totalsUnits
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }

   buildProjectsExportPayload: () =>
      sortBy = switch @viewConfig().projectSort
         when "earliest-start-date" then "start_date"
         when "earliest-end-date" then "est_end_date"
         when "project-number" then "job_number"
         else "name"

      return {
         project_sort: sortBy
         show_categories: @viewConfig().showCategories
         show_sub_categories: @viewConfig().showSubCategories
         show_project_numbers: @viewConfig().showJobNumbers
         ...@createBasePayload()
      }

   showExportModal: () =>
      return if @exportsDisabled()
      payload = if @viewConfig().viewBy == "project" then @buildProjectsExportPayload() else @createBasePayload()
      reportTargetPath = if @viewConfig().viewBy == "project" then "projects-totals-report" else "job-titles-totals-report"
      
      modal = new Modal()
      modal.setPanes([new TotalsExportModalPane(payload, "Export Totals Report", reportTargetPath)])
      modalManager.showModal(modal, null, {class: 'totals-export-modal-pane'})

   getFilterParams: () ->
      params = {}
      for chip in @filterChips()
         filter = {
            property: chip.property
            type: chip.type
            negation: chip.negation
         }

         if chip.customFieldId?
            filter['customFieldId'] = chip.customFieldId
            filter['filterName'] = chip.customFieldId
            filterName = chip.customFieldId
         else 
            if chip.filterName.indexOf(".") != -1
               filter['filterName'] = chip.property
               filterName = chip.property
            else
               filter['filterName'] = chip.filterName
               filterName = chip.filterName

         filter['classifier'] = chip.classifier if chip.classifier?

         if chip.enableRelativeDate
            filter.value = JSON.stringify(chip.value)
         else if chip.value instanceof Array
            filter['value'] = chip.value.map (i) -> return ko.unwrap(i.value)
         else
            filter['value'] = chip.value

         if params[filterName]?
            params[filterName].push(filter)
         else
            params[filterName] = [filter]

      return params
   
   showSaveViewModal: () =>
      pageSpecific = {
         total_cell_units: @viewConfig().totalsUnits,
         breakdown_by: @viewConfig().viewBy,
         show_project_numbers: @viewConfig().showJobNumbers,
         show_project_categories: @viewConfig().showCategories
         show_project_subcategories: @viewConfig().showSubCategories
      }
      modal = new Modal();
      pane = new SaveViewPane(
         App.PageName.TOTALS,
         {
            search: @projectQuery()
            filters: @filterChips()
            sortBy: @viewConfig().projectSort
            pageSpecific: pageSpecific
            viewingRange: @selectedRangeDays(),
         }
      );
      modal.setPanes([pane]);
      modalManager.showModal(
         modal,
         null,
         { class: "save-view-modal" },
      )
   
   loadSavedView: (savedViewId) =>
      savedViewPayload = await SavedViewStore.getSavedView(savedViewId).payload
      savedView = savedViewPayload.data
      return unless savedView?

      @setTitle(savedView.name)

      config = { 
         projectSort: savedView.view_config.sort_by
         totalsUnits: savedView.page_specific.total_cell_units
         viewBy: savedView.page_specific.breakdown_by
         showJobNumbers: savedView.page_specific.show_project_numbers or false
         showCategories: savedView.page_specific.show_project_categories or false
         showSubCategories: savedView.page_specific.show_project_subcategories or false
      }
      @viewConfig(config)

      if savedView.search?
         @projectQuery(savedView.search)
      
      if savedView.chip_filters?
         chipFilters = savedView.chip_filters.map (item) -> 
            return FormatUtils.snakeCaseObjectToCamelCase(item)
            
         if chipFilters?
            @filterChips(chipFilters)
            setTimeout( () =>
               @chipFilterMediator.updateVisibleFilters(@filterChips().slice(0))
            , 0)
      
      if savedView.view_config?.viewing_range?
         @selectedRangeDays(savedView.view_config.viewing_range)
      
      @makeInitialLoadCalls()

   loadData: =>
      @loadTotals()

      entities = ['positions', 'projects_custom_field_filters', 'people_custom_field_filters']
      if @tagCategoriesEnabled
         entities.push('categorized-tags')
      else
         entities.push('tags')

      groupStore.getGroupEntities authManager.selectedGroupId(), entities, (err, data) =>
         return console.log "get group entity options err: ", err if err?

         # Rename custom fields that exist on both people on projects.
         if data.peopleCustomFieldFilters?.length and data.projectsCustomFieldFilters?.length
            for field in data.peopleCustomFieldFilters
               duplicates = data.projectsCustomFieldFilters.filter((f) => f.id == field.id)
               for projectCustomField in duplicates
                  projectCustomField.name = "Project's #{projectCustomField.name}"
               if duplicates.length
                  field.name = "Person's #{field.name}"

         filterChipOptions = {
            # TODO: Build out this option.
            # "Only Show": ko.observable({
            #    property: "only_show"
            #    values: [
            #       new ValueSet({name: "Assignments", value: 'assignments'})
            #       new ValueSet({name: "Requests", value: 'requests'})
            #    ]
            # })
            "Status": ko.observable({
               property: "status"
               values: [
                  new ValueSet({name: "Active", value: "active"})
                  new ValueSet({name: "Pending", value: "pending"})
                  new ValueSet({name: "Inactive", value: "inactive"})
               ]
            })
            "Bid Rate": ko.observable({
               property: "bid_rate"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })
            "Job Title": ko.observable({
               property: "position_id"
               values: data.positionOptions
            }),
            "Percent Complete": ko.observable({
               property: "percent_complete"
               disableSearch: true
               classifiers: [
                  {listLabel: "< (Less Than)", chipLabel: "<", value: "<"}, 
                  {listLabel: "<= (Less Than or Equal To)", chipLabel: "<=", value: "<="}, 
                  {listLabel: "= (Equal To)", chipLabel: "=", value: '='}, 
                  {listLabel: ">= (Greater Than or Equal To)", chipLabel: ">=", value: ">="},
                  {listLabel: "> (Greater Than)", chipLabel: ">", value: ">"}
               ]
               type: "number"
            })
            "Start Date": ko.observable(buildDateFilterInstance('start_date'))
            "Est. End Date": ko.observable(buildDateFilterInstance('est_end_date'))
         }

         if data.tagOptions? and data.tagOptions.length > 0
            filterChipOptions["Tags"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               values: FormatUtils.keyableSort(data.tagOptions, 'name')
            })
         else if data.categorizedTagOptions?
            classifiers = []
            for key of data.categorizedTagOptions
               classifiers.push({listLabel: key, chipLabel: null, value: key})
            classifiers.sort (a, b) ->
               if a.listLabel.toLowerCase() < b.listLabel.toLowerCase()
                  return -1
               else if a.listLabel.toLowerCase() > b.listLabel.toLowerCase()
                  return 1
               else
                  return 0

            filterChipOptions["Tags"] = ko.observable({
               property: "tag_instances"
               type: "multi-select"
               classifiers: classifiers
               classifierPaneName: "Tag Category"
               values: data.categorizedTagOptions
               backEnabled: true
            })

         accumulateCustomFieldChipFilters(
            filterChipOptions,
            data.projectsCustomFieldFilters,
            {
               propertyName: "custom_fields",
               sensitiveFields: authManager.projectsSensitiveFields(),
               canViewSensitiveFields: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_SENSITIVE),
               canViewFinancials: @canViewProjectFinancials,
            },
         )
         accumulateCustomFieldChipFilters(
            filterChipOptions,
            data.peopleCustomFieldFilters,
            {
               propertyName: "people_custom_fields",
               sensitiveFields: authManager.peopleSensitiveFields(),
               canViewSensitiveFields: authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_SENSITIVE),
               canViewFinancials: @canViewPeopleFinancials,
            },
         )

         @labeledFilterOptions(filterChipOptions)

   loadTotals: =>
      if @currentRequest()?
         @currentRequest(null)
      @pageIndex(0)
      @noResultsFound(false)

      if @selectedRangeDays() == 1
         endDay = @viewStartDay()
      else
         endDate = new Date(@viewStartDate().getTime())
         endDate.setDate(endDate.getDate() + (@selectedRangeDays() - 1))
         endDay = DateUtils.getDetachedDay(endDate)

      queryDepth = 0

      options = {
         startDay: @viewStartDay()
         endDay: endDay
         totalsUnit: @viewConfig().totalsUnits
         viewBy: @viewConfig().viewBy
         showCategories: @viewConfig().showCategories
         showSubCategories: @viewConfig().showSubCategories
         skip: queryDepth
         limit: @itemsPerRequest
         filters: @getFilterParams()
         search: @projectQuery()
         timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
         includeResourcesOutsideGroup: if @resourcesGroupFilterFlag() then @viewConfig().includeResourcesOutsideGroup else false
      }

      unless @canViewProjectFinancials
         if options.viewBy == "cost"
            options.viewBy = "people"

      buildingTotalData = {
         rollupData: { 
            dailyAssTotals: {}, 
            dailyRequestTotals: {},
            dailyCategories: {},
            dailyTimeOffTotals: {}
         }
         breakdownData: []
      }

      # Clear any existing data from either gantt view.
      $("#totals-rollup__x-axis").empty()
      $("#totals-breakdown__x-axis").empty()
      $("#totals-rollup-chart-wrapper").empty()
      $("#totals-wrapper").empty()
      @loadingMediator.hideLoader()

      @totalPossible(null)
      @totalsData(null)
      @loadingDepth()
      @loadingMediator.showLoader()
      # @loadingMediator.startProgress()

      # Used to calculate the y-axis for the project breakdown. Defined here to keep the running
      # total across multiple data refreshes.
      pageLeadingProjects = 0
      pageLeadingCategories = 0
      pageLeadingSubCategories = 0
      pageLeadingRequestTotalRows = 0

      executeDataFetch = =>
         @loadingData(true)

         # Make sure this value is not undefined so it doesn't 422 in the route validation
         options.includeResourcesOutsideGroup = options.includeResourcesOutsideGroup ? false;

         try
            options['group_id'] = authManager.selectedGroupId();
            request = await TotalsStore.getTotals(options).payload;
            data = {
               totalPossible: request.data.total_possible
               assignablePeopleCount: request.data.assignable_people_count
               rollupData: request.data.rollup_data
               breakdownData: request.data.breakdown_data
            }

            @totalPossible(data.totalPossible)
            @currentActiveResourceCount(data.assignablePeopleCount)

            unless options.skip >= @totalPossible() or @itemsPerRequest >= @totalPossible()
               # Go ahead and make the next request while we process the recently returned data locally. 
               # the next request shouldn't be able to finish before we finish the limtied processing.
               options.skip += @itemsPerRequest
               @loadingDepth(options.skip)
               executeDataFetch()

            # Add returned data.
            for key, val of data.rollupData.daily_ass_totals
               if options.totalsUnit == "people"
                  if buildingTotalData.rollupData.dailyAssTotals[key]?
                     for personId in val.peopleIds
                        unless buildingTotalData.rollupData.dailyAssTotals[key].peopleIds.indexOf(personId) != -1
                           buildingTotalData.rollupData.dailyAssTotals[key].peopleIds.push(personId)
                  else
                     buildingTotalData.rollupData.dailyAssTotals[key] = val
               else
                  if buildingTotalData.rollupData.dailyAssTotals[key]?
                     buildingTotalData.rollupData.dailyAssTotals[key] += val
                  else
                     buildingTotalData.rollupData.dailyAssTotals[key] = val

            for key, val of data.rollupData.daily_request_totals
               if options.totalsUnit == "people"
                  if buildingTotalData.rollupData.dailyRequestTotals[key]?
                     for requestId in val.requestIds
                        unless buildingTotalData.rollupData.dailyRequestTotals[key].requestIds.indexOf(requestId) != -1
                           buildingTotalData.rollupData.dailyRequestTotals[key].requestIds.push(requestId)
                  else
                     buildingTotalData.rollupData.dailyRequestTotals[key] = val
               else
                  if buildingTotalData.rollupData.dailyRequestTotals[key]?
                     buildingTotalData.rollupData.dailyRequestTotals[key] += val
                  else
                     buildingTotalData.rollupData.dailyRequestTotals[key] = val
            
            for key, val of data.rollupData.daily_time_off_totals
               if options.totalsUnit == "people"
                  if buildingTotalData.rollupData.dailyTimeOffTotals[key]?
                     for timeOffId in val.timeOffIds
                        unless buildingTotalData.rollupData.dailyTimeOffTotals[key].timeOffIds.indexOf(timeOffId) != -1
                           buildingTotalData.rollupData.dailyTimeOffTotals[key].timeOffIds.push(timeOffId)
                  else
                     buildingTotalData.rollupData.dailyTimeOffTotals[key] = val
               else
                  if buildingTotalData.rollupData.dailyTimeOffTotals[key]?
                     buildingTotalData.rollupData.dailyTimeOffTotals[key] += val
                  else
                     buildingTotalData.rollupData.dailyTimeOffTotals[key] = val

            compare = (aNum, bNum) ->
               return -1 if aNum < bNum
               return 1 if aNum > bNum
               return 0
            if options.skip >= @totalPossible() or @itemsPerRequest >= @totalPossible()
               # We are done loading. Sort and draw. 
               if options.viewBy == "job-title"
                  data.breakdownData.sort (a, b) ->
                     return a.sequence - b.sequence
               else if options.viewBy == "project"
                  # TODO: Make this responsive to project sort options.
                  if @viewConfig().projectSort == "project-number"
                     data.breakdownData.sort (a, b) ->
                        aNum = a.job_number or 'zzzzzzzzzz'
                        bNum = b.job_number or 'zzzzzzzzzz'
                        compare(aNum, bNum)
                  else if @viewConfig().projectSort == "earliest-start-date"
                     data.breakdownData.sort (a, b) ->
                        aNum = String(a.start_date) or 'zzzzzzzzzz'
                        bNum = String(b.start_date) or 'zzzzzzzzzz'
                        compare(aNum, bNum)
                  else if @viewConfig().projectSort == "earliest-end-date"
                     data.breakdownData.sort (a, b) ->
                        aNum = String(a.est_end_date) or 'zzzzzzzzzz'
                        bNum = String(b.est_end_date) or 'zzzzzzzzzz'
                        compare(aNum, bNum)
                  else
                     data.breakdownData.sort (a, b) ->
                        compare(a.name, b.name)

            if options.viewBy == "job-title"
               # Look for existing options
               for position in data.breakdownData
                  foundMatch = false
                  for item in buildingTotalData.breakdownData
                     if item.id == position.id
                        foundMatch = true
                        for key, val of position.daily_ass_totals
                           if options.totalsUnit == "people"
                              if item.dailyAssTotals[key]?
                                 for personId in val.peopleIds
                                    unless item.dailyAssTotals[key].peopleIds.indexOf(personId) != -1
                                       item.dailyAssTotals[key].peopleIds.push(personId)
                              else
                                 item.dailyAssTotals[key] = val
                           else
                              if item.dailyAssTotals[key]?
                                 item.dailyAssTotals[key] += val
                              else
                                 item.dailyAssTotals[key] = val
                        for key, val of position.daily_request_totals
                           if options.totalsUnit == "people"
                              if item.dailyRequestTotals[key]?
                                 for requestId in val.requestIds
                                    unless item.dailyRequestTotals[key].requestIds.indexOf(requestId) != -1
                                       item.dailyRequestTotals[key].requestIds.push(requestId)
                              else
                                 item.dailyRequestTotals[key] = val
                           else
                              if item.dailyRequestTotals[key]?
                                 item.dailyRequestTotals[key] += val
                              else
                                 item.dailyRequestTotals[key] = val

                        for key, val of position.daily_time_off_totals
                           if options.totalsUnit == "people"
                              if item.dailyTimeOffTotals[key]?
                                 for timeOffId in val.timeOffIds
                                    unless item.dailyTimeOffTotals[key].timeOffIds.indexOf(timeOffId) != -1
                                       item.dailyTimeOffTotals[key].timeOffIds.push(timeOffId)
                              else
                                 item.dailyTimeOffTotals[key] = val
                           else
                              if item.dailyTimeOffTotals[key]?
                                 item.dailyTimeOffTotals[key] += val
                              else
                                 item.dailyTimeOffTotals[key] = val
                        break
                  unless foundMatch
                     position['dailyAssTotals'] = position.daily_ass_totals
                     position['dailyRequestTotals'] = position.daily_request_totals
                     position['dailyTimeOffTotals'] = position.daily_time_off_totals
                     delete position.daily_ass_totals
                     delete position.daily_request_totals
                     delete position.daily_time_off_totals
                     buildingTotalData.breakdownData.push(position)

            else if options.viewBy == "project"
               for project in data.breakdownData
                  project['dailyAssTotals'] = project.daily_ass_totals
                  project['dailyRequestTotals'] = project.daily_request_totals
                  project['dailyTimeOffTotals'] = project.daily_time_off_totals
                  project['dailyCategories'] = project.categories

                  if pageLeadingProjects >= @pageDrawLimit
                     pageLeadingProjects = 0
                     pageLeadingCategories = 0
                     pageLeadingSubCategories = 0
                     pageLeadingRequestTotalRows = 0
                  projectLeadingCategories = 0
                  projectLeadingSubCategories = 0
                  projectLeadingRequestTotalRows = 0

                  project['leadingProjects'] = pageLeadingProjects
                  project['leadingCategories'] = pageLeadingCategories
                  project['leadingSubcategories'] = pageLeadingSubCategories
                  project['leadingRequestTotalRows'] = pageLeadingRequestTotalRows
                  
                  projectLeadingRequestTotalRows += 2 if @canViewRequests
                  previousCategoryHasNoSubCategories = false
                  for category in (project.categories or [])
                     categoryLeadingSubCategories = 0
                     categoryLeadingRequestTotalRows = 0
                     category['previousCategoryHasNoSubCategories'] = previousCategoryHasNoSubCategories
                     previousCategoryHasNoSubCategories = false if previousCategoryHasNoSubCategories
                     if (category.subcategories or []).length == 0 and @viewConfig().showSubCategories
                        previousCategoryHasNoSubCategories = true
                     for subCategory in (category.subcategories or [])
                        subCategory['leadingSubCategories'] = categoryLeadingSubCategories
                        subCategory['leadingRequestTotalRows'] = if @canViewRequests then (categoryLeadingRequestTotalRows or 2) else 0
                        subCategory['containedRequestTotalRows'] = if @canViewRequests then 2 else 0
                        if @viewConfig().showSubCategories
                           categoryLeadingSubCategories++
                           categoryLeadingRequestTotalRows += 2 if @canViewRequests

                     category['leadingCategories'] = if @viewConfig().showCategories then projectLeadingCategories else 0
                     category['leadingSubCategories'] = projectLeadingSubCategories
                     category['leadingRequestTotalRows'] = projectLeadingRequestTotalRows
                     category['containedSubCategories'] = categoryLeadingSubCategories
                     category['containedRequestTotalRows'] = if @canViewRequests then (categoryLeadingRequestTotalRows + 2) else 0
                     
                     if @viewConfig().showCategories
                        categoryLeadingRequestTotalRows += 2 if @canViewRequests
                        projectLeadingSubCategories += categoryLeadingSubCategories
                        projectLeadingRequestTotalRows += categoryLeadingRequestTotalRows
                        projectLeadingCategories++ 

                  project['containedCategories'] = projectLeadingCategories
                  project['containedSubcategories'] = projectLeadingSubCategories
                  project['containedRequestTotalRows'] = projectLeadingRequestTotalRows

                  pageLeadingProjects++
                  pageLeadingCategories += projectLeadingCategories
                  pageLeadingSubCategories += projectLeadingSubCategories
                  pageLeadingRequestTotalRows += projectLeadingRequestTotalRows

                  delete project.daily_ass_totals
                  delete project.daily_request_totals
                  delete project.categories

               buildingTotalData.breakdownData = buildingTotalData.breakdownData.concat(data.breakdownData)

            if options.skip >= @totalPossible() or @itemsPerRequest >= @totalPossible()
               # This order is important.
               @loadingData(false)
               @totalsData(buildingTotalData)
               @loadingMediator.hideLoader()
               if buildingTotalData.breakdownData.length > 0
                  @handleRedraw()
               else
                  @noResultsFound(true)

         catch error
            console.log error
         
         @currentRequest(request)

      executeDataFetch()

   dispose: (next) ->
      @groupIdSubscription.dispose()
      next()


TotalsViewModel.VALID_RANGE_OPTIONS = [7, 14, 28, 56, 91, 182, 364]

TotalsViewModel.BrowserStorageKeys = {
   TOTALS_VIEW_CONFIG: "totals-view-config"
   TOTALS_FILTER_CHIPS: "totals-filter-chips"
}

TotalsViewModel.DEFAULT_FILTER_CHIPS = [{
   classifier: null,
   classifierLabel: null,
   customFieldId: null,
   filterName: "Status",
   property: "status",
   value: "active",
   valueName: "Active"
}]

TotalsViewModel.VIEW_DEFAULTS = {
   totalsUnits: "people"
   viewBy: "job-title"
   projectSort: "name"
   showJobNumbers: false
   showCategories: false
   showSubCategories: false
   includeResourcesOutsideGroup: false
}
