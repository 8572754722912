import "./map.styl"
import template from "./map.pug"
import { App } from "@/views/app"
import { ValidationUtils } from "@/lib/utils/validation"
import { router } from "@/lib/router"
import { PageContentViewModel } from "@/lib/vm/page-content-viewmodel"
import ko from "knockout";
import {
   FilterFieldType,
} from "@laborchart-modules/common/dist/rethink/schemas/generated-reports/enums/common";
import { ProgressNotification } from "@/notifications/progress-notification";
import {
   notificationManagerInstance,
} from "@/lib/managers/notification-manager";

### Auth, Real-Time & Stores ###
import { authManager } from "@/lib/managers/auth-manager"
import { projectStore } from "@/stores/project-store"
import { peopleStore } from "@/stores/people-store"
import { ProjectStore } from "@/stores/project-store.core"
import { PersonStore } from "@/stores/person-store.core"

### Models ###
import { PermissionLevel } from "@/models/permission-level"

### Popups ###
import { Popup } from "@/lib/components/popup/popup"
import { PopupListPane } from "@/lib/components/popup/popup-list-pane"
import { PopupListItem } from "@/lib/components/popup/popup-list-item"
import { ProjectsFilterPane } from "@/lib/components/popup/projects-filter-pane"
import { PeopleFilterPane } from "@/lib/components/popup/people-filter-pane"

### UI Assets ###
import { SegmentedControllerItem } from "@/lib/components/segmented-controller/segmented-controller"

export class MapViewModel extends PageContentViewModel
   constructor: (queryParams) ->
      super(template(), "Map")

      ###------------------------------------
         Permissions
      ------------------------------------###
      @canViewPeople = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE)
      @canViewPeopleTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PEOPLE_TAGS)
      @canViewProject = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT)
      @canViewProjectTags = authManager.checkAuthAction(PermissionLevel.Action.VIEW_PROJECT_TAGS)

      @showProjects = ko.observable(true)
      if @canViewProject
         @showProjects(false) if queryParams?.showPr? and queryParams?.showPr == "false"
         @showProjects.subscribe (newVal) =>
            router.updateUrlQueryParam(App.RouteName.MAP, "showPr", String(newVal))
      else
         @showProjects(false)

      @showPeople = ko.observable(true)
      if @canViewPeople
         @showPeople(false) if queryParams?.showPe? and queryParams?.showPe == "false"
         @showPeople.subscribe (newVal) =>
            router.updateUrlQueryParam(App.RouteName.MAP, "showPe", String(newVal))
      else
         @showPeople(false)
      @markerData = ko.observable({projects: [], people: []})

      # Tray Controls
      @showingLeftTray = ko.observable(true)
      @showingConfigPane = ko.observable(true)
      @showingListPane = ko.observable(false)

      @loadingProjectDetails = ko.observable(false)
      @loadingPersonDetails = ko.observable(false)
      @selectedProject = ko.observable(null)
      @selectedProjectName = ko.observable(null)
      @selectedPerson = ko.observable(null)
      @selectedPersonName = ko.observable(null)
      @projectMilesRadius = ko.observable(null)
      @personMilesRadius = ko.observable(null)

      @hasSelectedEntity = ko.pureComputed =>
         return @selectedProjectName()? or @selectedPersonName()?

      authManager.selectedGroupId.subscribe () => @loadData()

      # Popups
      @selectedProjectMorePopupBuilder = =>
         items = [new PopupListItem({title: "Project Details", icon: "icon-building-sidemenu", clickCallback: @navigateToProjectDetail})]
         return new Popup("Create New", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new PopupListPane("Create New", items)],
            ['map-page__selected-details__more-btn', 'map-page__selected-details__header__btn-container'], ["selected-entity__popup--more"])

      @selectedProjectMorePopupWrapper = {
         popupBuilder: @selectedProjectMorePopupBuilder
         options: {triggerClasses: ['map-page__selected-details__more-btn']}
      }

      # Popups
      @selectedPersonMorePopupBuilder = =>
         items = [new PopupListItem({title: "Person Details", icon: "icon-sidemenu-team", clickCallback: @navigateToPersonDetail})]
         return new Popup("Create New", Popup.FrameType.BELOW, Popup.ArrowLocation.TOP_RIGHT,
            [new PopupListPane("Create New", items)],
            ['map-page__selected-details__more-btn', 'map-page__selected-details__header__btn-container'], ["selected-entity__popup--more"])

      @selectedPersonMorePopupWrapper = {
         popupBuilder: @selectedPersonMorePopupBuilder
         options: {triggerClasses: ['map-page__selected-details__more-btn']}
      }

      # Triggers
      @clearProjectMarkerTrigger = ko.observable(true)
      @clearPersonMarkerTrigger = ko.observable(true)
      @selectMarkerTrigger = ko.observable(null)
      @zoomInTrigger = ko.observable(true)
      @zoomOutTrigger = ko.observable(true)

      @assignmentHeaderText = ko.pureComputed =>
         return if @selectedEntityAssignments().length == 1 then "1 Assignment" else "#{@selectedEntityAssignments().length} Assignments"

      @selectedPersonColor = ko.pureComputed =>
         if @selectedPerson()? and @selectedPerson().position()?
            return @selectedPerson().position().color()
         return null

      @selectedProjectColor = ko.pureComputed =>
         if @selectedProject()?
            return @selectedProject().color()
         return null

      @selectedPersonTags = ko.pureComputed =>
         if @selectedPerson()?
            return @selectedPerson().tagInstances()
         return []

      @selectedProjectTags = ko.pureComputed =>
         if @selectedProject()?
            return @selectedProject().tagInstances()
         return []

      @projectStreetAddress = ko.pureComputed =>
         return unless @selectedProject()?
         address = ""
         address += "#{@selectedProject().address1()} " if @selectedProject().address1()?
         address += @selectedProject().address2() if @selectedProject().address2()?
         return address

      @projectLocation = ko.pureComputed =>
         return unless @selectedProject()?
         location = ""
         location += "#{@selectedProject().cityTown()}, " if @selectedProject().cityTown()?
         location += "#{@selectedProject().stateProvince()} " if @selectedProject().stateProvince()?
         location += "#{@selectedProject().zipcode() } " if @selectedProject().zipcode()?
         location += "#{@selectedProject().country() }" if @selectedProject().country()?
         return location

      @leftTrayTitle = ko.pureComputed =>
         projectCount = @markerData().projects.length
         projectString = if projectCount == 1 then "1 Project" else "#{projectCount} Projects"
         peopleCount = @markerData().people.length
         peopleString = if peopleCount == 1 then "1 Person" else "#{peopleCount} People"
         
         if @canViewProject and @canViewPeople
            return "#{projectString} / #{peopleString}"
         else if @canViewPeople
            return peopleString
         else if @canViewProject
            return projectString

      @configTabIcon = ko.pureComputed =>
         return "icon-filter-tab" unless @showingLeftTray()
         return if @showingConfigPane() then "icon-left-arrow-black" else "icon-filter-tab"

      @listTabIcon = ko.pureComputed =>
         return "icon-list-tab" unless @showingLeftTray()
         return if @showingListPane() then "icon-left-arrow-black" else "icon-list-tab"

      @projectQuery = ko.observable()
      if queryParams?.prq? and ValidationUtils.validateInput(queryParams.prq)
         @projectQuery(decodeURIComponent(queryParams.prq))
      @projectQuery.subscribe (newVal) ->
         if ValidationUtils.validateInput(newVal)
            router.updateUrlQueryParam(App.RouteName.MAP, "prq", encodeURIComponent(newVal))
         else
            router.removeQueryParam(App.RouteName.MAP, 'prq')

      @peopleQuery = ko.observable()
      if queryParams?.peq? and ValidationUtils.validateInput(queryParams.peq)
         @peopleQuery(decodeURIComponent(queryParams.peq))
      @peopleQuery.subscribe (newVal) ->
         if ValidationUtils.validateInput(newVal)
            router.updateUrlQueryParam(App.RouteName.MAP, "peq", encodeURIComponent(newVal))
         else
            router.removeQueryParam(App.RouteName.MAP, 'peq')

      @entityTypeOptions = ko.observableArray([
         new SegmentedControllerItem("Projects", MapViewModel.ListPaneEntityType.PROJECTS)
         new SegmentedControllerItem("People", MapViewModel.ListPaneEntityType.PEOPLE)
      ])
      @listPaneViewingEntity = ko.observable(@entityTypeOptions()[0])

      @listingEntity = ko.pureComputed =>
         if @canViewProject and @listPaneViewingEntity().value() == MapViewModel.ListPaneEntityType.PROJECTS
            return @markerData().projects
         else if @canViewPeople
            return @markerData().people

      @entityDisplayOptions = ko.observableArray([
         new SegmentedControllerItem("All", MapViewModel.EntityDisplayOption.ALL)
         new SegmentedControllerItem("Active", MapViewModel.EntityDisplayOption.ACTIVE)
         new SegmentedControllerItem("Inactive", MapViewModel.EntityDisplayOption.INACTIVE)
         new SegmentedControllerItem("None", MapViewModel.EntityDisplayOption.NONE)
      ])
      @selectedProjectDisplayOption = ko.observable(@entityDisplayOptions()[1])
      if queryParams?.prdo? and ValidationUtils.validateInput(queryParams.prdo)
         for option in @entityDisplayOptions()
            if option.value() == queryParams.prdo
               @selectedProjectDisplayOption(option)
               break
      @selectedProjectDisplayOption.subscribe (newVal) =>
         if @canViewProject
            @showProjects(newVal.value() != MapViewModel.EntityDisplayOption.NONE)
            router.updateUrlQueryParam(App.RouteName.MAP, "prdo", newVal.value())
         @loadData(false, true)

      @selectedPeopleDisplayOption = ko.observable(@entityDisplayOptions()[1])
      if queryParams?.pdo? and ValidationUtils.validateInput(queryParams.pdo)
         for option in @entityDisplayOptions()
            if option.value() == queryParams.pdo
               @selectedPeopleDisplayOption(option)
               break
      @selectedPeopleDisplayOption.subscribe (newVal) =>
         if @canViewPeople
            @showPeople(newVal.value() != MapViewModel.EntityDisplayOption.NONE)
            router.updateUrlQueryParam(App.RouteName.MAP, "pdo", newVal.value())
         @loadData(true, false)

      # Project Filters
      @projectTagFilters = ko.observableArray([])
      if queryParams?.prtf? and ValidationUtils.validateInput(queryParams.prtf)
         @projectTagFilters(queryParams.prtf.split(","))
      @projectTagFilters.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'prtf') if newVal.length == 0
         router.updateUrlQueryParam(App.RouteName.MAP, "prtf", newVal.join(','))

      @bidRateLowFilter = ko.observable(null)
      if queryParams?.brlf? and ValidationUtils.validateInput(queryParams.brlf)
         @bidRateLowFilter(Number(queryParams.brlf))
      @bidRateLowFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'brlf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "brlf", newVal)

      @bidRateHighFilter = ko.observable(null)
      if queryParams?.brhf? and ValidationUtils.validateInput(queryParams.brhf)
         @bidRateHighFilter(Number(queryParams.brhf))
      @bidRateHighFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'brhf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "brhf", newVal)

      @startDateLow = ko.observable(null)
      if queryParams?.sdl? and ValidationUtils.validateInput(queryParams.sdl)
         @startDateLow(new Date(Number(queryParams.sdl)))
      @startDateLow.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'sdl') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "sdl", newVal.setHours(0,0,0,0))

      @startDateHigh = ko.observable(null)
      if queryParams?.sdh? and ValidationUtils.validateInput(queryParams.sdh)
         @startDateHigh(new Date(Number(queryParams.sdh)))
      @startDateHigh.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'sdh') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "sdh", newVal.setHours(0,0,0,0))

      @endDateLow = ko.observable(null)
      if queryParams?.edl? and ValidationUtils.validateInput(queryParams.edl)
         @endDateLow(new Date(Number(queryParams.edl)))
      @endDateLow.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'edl') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "edl", newVal.setHours(0,0,0,0))

      @endDateHigh = ko.observable(null)
      if queryParams?.edh? and ValidationUtils.validateInput(queryParams.edh)
         @endDateHigh(new Date(Number(queryParams.edh)))
      @endDateHigh.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'edh') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "edh", newVal.setHours(0,0,0,0))

      @appliedProjectFilterCount = ko.pureComputed =>
         totalFilters = @projectTagFilters().length
         totalFilters++ if @bidRateLowFilter()? or @bidRateHighFilter()?
         totalFilters++ if @startDateLow()? or @startDateHigh()?
         totalFilters++ if @endDateLow()? or @endDateHigh()?
         return if totalFilters == 1 then "1 Filter Applied" else "#{totalFilters} Filters Applied"

      # Project Filter Popup
      @projectFilterPopupBuilder = =>
         selectedFilters = {
            selectedTagIds: @projectTagFilters
            bidRateLow: @bidRateLowFilter
            bidRateHigh: @bidRateHighFilter
            startDateLow: @startDateLow
            startDateHigh: @startDateHigh
            endDateLow: @endDateLow
            endDateHigh: @endDateHigh
         }
         return new Popup "Filters", Popup.FrameType.LEFT, Popup.ArrowLocation.RIGHT_CENTER,
            [new ProjectsFilterPane(selectedFilters)],
            ['map-page__filter-btn-container', 'icon-gear-dark'], ['map-page__popup--project-filter'],
            (err, exitStatus) =>
               return if exitStatus == Popup.ExitStatus.CANCELED
               @loadData(false, true)

      @projectFilterPopupWrapper = ko.observable({
         popupBuilder: @projectFilterPopupBuilder
         options: {triggerClasses: ['map-page__filter-btn-container', 'icon-gear-dark']}
      })

      # People Filters
      @positionFilters = ko.observableArray([])
      if queryParams?.pf? and ValidationUtils.validateInput(queryParams.pf)
         @positionFilters(queryParams.pf.split(","))
      @positionFilters.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'pf') if newVal.length == 0
         router.updateUrlQueryParam(App.RouteName.MAP, "pf", newVal.join(','))

      @peopleTagFilters = ko.observableArray([])
      if queryParams?.petf? and ValidationUtils.validateInput(queryParams.petf)
         @peopleTagFilters(queryParams.petf.split(","))
      @peopleTagFilters.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'petf') if newVal.length == 0
         router.updateUrlQueryParam(App.RouteName.MAP, "petf", newVal.join(','))

      @wageLowFilter = ko.observable(null)
      if queryParams?.wlf? and ValidationUtils.validateInput(queryParams.wlf)
         @wageLowFilter(Number(queryParams.wlf))
      @wageLowFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'wlf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "wlf", newVal)

      @wageHighFilter  = ko.observable(null)
      if queryParams?.whf? and ValidationUtils.validateInput(queryParams.whf)
         @wageHighFilter(Number(queryParams.whf))
      @wageHighFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'whf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "whf", newVal)

      @ageLowFilter = ko.observable(null)
      if queryParams?.alf? and ValidationUtils.validateInput(queryParams.alf)
         @ageLowFilter(Number(queryParams.alf))
      @ageLowFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'alf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "alf", newVal)

      @ageHighFilter = ko.observable(null)
      if queryParams?.ahf? and ValidationUtils.validateInput(queryParams.ahf)
         @ageHighFilter(Number(queryParams.ahf))
      @ageHighFilter.subscribe (newVal) ->
         return router.removeQueryParam(App.RouteName.MAP, 'ahf') unless ValidationUtils.validateInput(newVal)
         router.updateUrlQueryParam(App.RouteName.MAP, "ahf", newVal)

      @appliedPeopleFilterCount = ko.pureComputed =>
         positionsCount = @positionFilters().length
         tagsCount = @peopleTagFilters().length
         totalFilters = positionsCount + tagsCount
         totalFilters++ if @wageLowFilter()? or @wageHighFilter()?
         totalFilters++ if @ageLowFilter()? or @ageHighFilter()?
         return if totalFilters == 1 then "1 Filter Applied" else "#{totalFilters} Filters Applied"

      # People Filter Popup
      @peopleFilterPopupBuilder = =>
         return new Popup "Filters", Popup.FrameType.LEFT, Popup.ArrowLocation.RIGHT_CENTER,
            [new PeopleFilterPane(@positionFilters, @peopleTagFilters, @wageLowFilter, @wageHighFilter,
            @ageLowFilter, @ageHighFilter)],
            ['map-page__filter-btn-container', 'icon-gear-dark'], ['map-page__popup--people-filter'],
            (err, exitStatus) =>
               return if exitStatus == Popup.ExitStatus.CANCELED
               @loadData(true, false)

      @peopleFilterPopupWrapper = {
         popupBuilder: @peopleFilterPopupBuilder
         options: {triggerClasses: ['map-page__filter-btn-container', 'icon-gear-dark']}
      }

      @loadData()

      @grayoutResetButton = ko.pureComputed =>
         return false unless @showPeople() and @showProjects()
         return false unless !ValidationUtils.validateInput(@peopleQuery())
         return false unless !ValidationUtils.validateInput(@projectQuery())
         positionsCount = @positionFilters().length
         peopleTagsCount = @peopleTagFilters().length
         peopleTotalFilters = positionsCount + peopleTagsCount
         peopleTotalFilters++ if @wageLowFilter()? or @wageHighFilter()?
         peopleTotalFilters++ if @ageLowFilter()? or @ageHighFilter()?
         return false unless peopleTotalFilters == 1
         projectTagsCount = @projectTagFilters().length
         projectTotalFilters = projectTagsCount
         projectTotalFilters++ if @bidRateLowFilter()? or @bidRateHighFilter()?
         projectTotalFilters++ if @startDateLow()? or @startDateHigh()?
         projectTotalFilters++ if @endDateLow()? or @endDateHigh()?
         return false unless projectTotalFilters == 1
         return true

   zoomInClicked: ->
      @zoomInTrigger(!@zoomInTrigger())

   zoomOutClicked: ->
      @zoomOutTrigger(!@zoomOutTrigger())

   handleProjectQueryExecution: =>
      @loadData(false, true)

   handlePeopleQueryExecution: =>
      @loadData(true, false)

   getEntityName: (entity) ->
      if typeof entity.name == "string"
         return entity.name
      else
         "#{entity.name.first} #{entity.name.last}"

   showEntityOnMap: (entityId) ->
      @selectMarkerTrigger(entityId)

   maybeShowLeftConfigPane: ->
      return @showingLeftTray(false) if @showingLeftTray() and @showingConfigPane()
      @showingLeftTray(true)
      @showingConfigPane(true)
      @showingListPane(false)

   maybeShowLeftListPane: ->
      return @showingLeftTray(false) if @showingLeftTray() and @showingListPane()
      @showingLeftTray(true)
      @showingListPane(true)
      @showingConfigPane(false)

   closeSelectedProject: ->
      @clearProjectMarkerTrigger(!@clearProjectMarkerTrigger())
      @selectedProject(null)
      @selectedProjectName(null)
      @projectMilesRadius(null)

   closeSelectedPerson: ->
      @clearPersonMarkerTrigger(!@clearPersonMarkerTrigger())
      @selectedPerson(null)
      @selectedPersonName(null)
      @personMilesRadius(null)

   applyConfigClicked: =>
      @loadData()

   resetConfigClicked: =>
      if @canViewProject
         @showProjects(true)
      else
         @showProjects(false)

      if @canViewPeople
         @showPeople(true)
      else
         @showPeople(false)

      @projectQuery(null)
      @peopleQuery(null)
      @clearProjectFilters(null)
      @clearPeopleFilters(null)

   # Using param as existential instead of bool as knockout also hits it.
   clearProjectFilters: () =>
      @selectedProjectDisplayOption(@entityDisplayOptions()[1])
      @projectTagFilters([])
      @bidRateLowFilter(null)
      @bidRateHighFilter(null)
      @startDateLow(null)
      @startDateHigh(null)
      @endDateLow(null)
      @endDateHigh(null)

   clearPeopleFilters: () =>
      @selectedPeopleDisplayOption(@entityDisplayOptions()[1])
      @positionFilters([])
      @peopleTagFilters([])
      @wageLowFilter(null)
      @wageHighFilter (null)
      @ageLowFilter(null)
      @ageHighFilter(null)

   navigateToProjectDetail: =>
      return unless @selectedProject()?
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/projects/#{@selectedProject().id}")

   navigateToPersonDetail: =>
      return unless @canViewPeople and @selectedPerson()?
      router.navigate(null, "/groups/#{authManager.selectedGroupId()}/people/#{@selectedPerson().id}")

   loadPerson: (personId, personName) =>
      return unless @canViewPeople
      @loadingPersonDetails(true)
      @selectedPersonName(personName)
      @selectedPerson(null)
      peopleStore.getAbbrvPerson personId, (err, person) =>
         return console.log "error: ", err if err
         @loadingPersonDetails(false)
         @selectedPerson(person)

   loadProject: (projectId, projectName) =>
      @loadingProjectDetails(true)
      @selectedProjectName(projectName)
      @selectedProject(null)
      projectStore.getAbbrvProject projectId, (err, project) =>
         return console.log "error: ", err if err
         @loadingProjectDetails(false)
         @selectedProject(project)

   getPeopleQueryParams: =>
      peopleFilters = [{
         name: "Has Location",
         property: "has_location",
         type: FilterFieldType.BOOL,
         value_sets: [{ value: true }],
      }]
      if (@selectedPeopleDisplayOption().value() != "all" and @selectedPeopleDisplayOption().value() != "none")
         peopleFilters.push({            
            name: "Status",
            property: "status",
            type: FilterFieldType.SELECT,
            value_sets: [{ value: @selectedPeopleDisplayOption().value() }],
         })

      if (@peopleTagFilters().length >= 1)
         peopleFilters.push({
            name: "Tag Instances",
            property: "tag_instances",
            type: FilterFieldType.MULTI_SELECT,
            value_sets: @peopleTagFilters().map((tagFilter) => ({ value: [tagFilter] }))
         })
      if (@positionFilters().length >= 1)
         peopleFilters.push({
            name: "Position",
            property: "position_id",
            type: FilterFieldType.SELECT,
            value_sets: @positionFilters().map((positionFilter) =>( { value: positionFilter })),         
         })
      
      queryParams = { filters: peopleFilters }
      queryParams['name'] = @peopleQuery() if ValidationUtils.validateInput(@peopleQuery())
      queryParams['group_id'] = authManager.selectedGroupId() if authManager.selectedGroupId() != 'my-groups'

      return queryParams

   getProjectQueryParams: =>
      projectFilters = [{
         name: "Has Location",
         property: "has_location",
         type: FilterFieldType.BOOL,
         value_sets: [{ value: true }],
      }]
      if (@selectedProjectDisplayOption().value() != "all" and @selectedProjectDisplayOption().value() != "none")
         projectFilters.push({            
            name: "Status",
            property: "status",
            type: FilterFieldType.SELECT,
            value_sets: [{ value: @selectedProjectDisplayOption().value() }],
         })

      if (@projectTagFilters().length >= 1)
         projectFilters.push({
            name: "Tag Instances",
            property: "tag_instances",
            type: FilterFieldType.MULTI_SELECT,
            value_sets: @projectTagFilters().map((tagFilter) => ({ value: [tagFilter] }))
         })
      
      queryParams = { filters: projectFilters }
      queryParams['name'] = @projectQuery() if ValidationUtils.validateInput(@projectQuery())
      queryParams['group_id'] = authManager.selectedGroupId() if authManager.selectedGroupId() != 'my-groups'
      return queryParams

   loadData: (loadPeople = true, loadProjects = true) ->
      loadPeopleRequest = () => 
         if loadPeople == false
            return null
         if @showPeople()
            peopleNotification = new ProgressNotification({
               message: "Loading people...",
               actions: [],
            })
            notificationManagerInstance.show(peopleNotification)

            @closeSelectedPerson()
            peopleQuery = @getPeopleQueryParams()
            people = []

            try 
               personStream = await PersonStore.findPeopleStream(peopleQuery).stream
               for await person from personStream
                  people.push({
                     id: person.id,
                     name: person.name,
                     lat: person.lat,
                     long: person.long,
                     profile_pic_url: person.profile_pic_url,
                  })
               peopleNotification.success({
                  message: "Loaded people successfully.",
               })
               dismissFunc = () => notificationManagerInstance.dismiss(peopleNotification)
               setTimeout(dismissFunc, 1000)
            catch
               peopleNotification.failed({
                  message: "Failed to load people."
               })
               return null
         return people or []
            
      
      loadProjectsRequest = () => 
         if loadProjects == false
            return null
         if @showProjects()
            projectNotification = new ProgressNotification({
               message: "Loading projects...",
               actions: [],
            })
            notificationManagerInstance.show(projectNotification)

            @closeSelectedProject()
            projectQuery = @getProjectQueryParams()
            projects = []

            try
               projectStream = await ProjectStore.findProjectsStream(projectQuery).stream
               for await project from projectStream
                  projects.push({
                     id: project.id,
                     name: project.name,
                     lat: project.lat,
                     long: project.long,
                     profile_pic_url: project.profile_pic_url,
                  })
               projectNotification.success({
                  message: "Loaded projects successfully.",
               })
               dismissFunc = () => notificationManagerInstance.dismiss(projectNotification)
               setTimeout(dismissFunc, 1000)
            catch
               projectNotification.failed({
                  message: "Failed to load projects."
               })
               return null
         return projects or []
      
      [people, projects] = await Promise.all([loadPeopleRequest(), loadProjectsRequest()])

      markers = {
         people: people or @markerData().people,
         projects: projects or @markerData().projects,
      }

      @markerData(markers)

MapViewModel.ListPaneEntityType = {
   PROJECTS: "projects"
   PEOPLE: "people"
}

MapViewModel.EntityDisplayOption = {
   ALL: "all"
   ACTIVE: "active"
   INACTIVE: "inactive"
   NONE: "none"
}